import { ChatConsultationSummary } from '~/types/ChatConsultationSummary';

export interface ChatConsultationApi {
  getChatConsultationSummaries: () => Promise<ChatConsultationSummary[]>;
  putChatConsultation: (projectId: string, chatId: string, messageId: string) => Promise<void>
  deleteChatConsultation: (projectId: string, chatId: string, messageId: string) => Promise<void>;
}

export const useChatConsultationApi = (fetch, axios): ChatConsultationApi => {
  /**
   * 医療相談サマリー取得
   */
  const getChatConsultationSummaries = async (): Promise<ChatConsultationSummary[]> => {
    const response = await fetch('medicalConsultationSummaries');
    return response.data.data;
  };

  /**
   * 医療相談完了
   */
  const putChatConsultation = async (projectId: string, chatId: string, messageId: string): Promise<void> => {
    await axios.put(`/projects/${projectId}/chat/${chatId}/consultation/${messageId}`);
  };

  /**
   * 医療相談削除
   */
  const deleteChatConsultation = async (projectId: string, chatId: string, messageId: string): Promise<void> => {
    await axios.delete(`/projects/${projectId}/chat/${chatId}/consultation/${messageId}`);
  };

  return {
    getChatConsultationSummaries,
    putChatConsultation,
    deleteChatConsultation,
  };
};
