import {
  Ref,
  ref,
} from '@nuxtjs/composition-api';
import { Location } from 'vue-router';

export interface DoctorHeaderState {
  isShowReturnBtn: Ref<boolean>;
  returnLink: Ref<Location | ''>;
  subtitle: Ref<string>;
}

export const useDoctorHeaderState = (): DoctorHeaderState => {
  const isShowReturnBtn = ref<boolean>(false);
  const returnLink = ref<Location | ''>('');
  const subtitle = ref<string>('');

  return {
    isShowReturnBtn,
    returnLink,
    subtitle,
  };
};
