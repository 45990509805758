import {
  computed,
  useContext,
} from '@nuxtjs/composition-api';

export const useBottomNavigation = () => {
  const { $s } = useContext();

  const visibleBadge = computed(() => {
    if (!$s.todoList.value) {
      return false;
    }

    const { deadline, noDeadline } = $s.todoList.value;
    return deadline.length > 0 || noDeadline.length > 0;
  });

  return {
    visibleBadge,
  };
};

export { default as ComponentBottomNavigationDoctor } from './BottomNavigation.doctor.vue';
export { default as ComponentBottomNavigationPatient } from './BottomNavigation.patient.vue';
