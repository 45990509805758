// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_2m-GX{display:flex;flex-direction:column;height:100%}.user_1WQ0b{border-bottom:1px solid var(--color-border-thin);padding:var(--gutter) var(--gap);display:flex;color:inherit!important;gap:15px}.userImage_2lW0e{width:50px;height:50px}.userName_1zCar{font-size:1.11rem}.userName_1zCar>span{display:block;font-size:.72rem;color:var(--v-anchor-base);font-weight:400}.other_6G8aR{margin-top:auto;border-top:1px solid var(--color-border-thin);padding:var(--gutter) var(--gap)}.otherList_31zvf{padding:0!important}.otherList_31zvf>li{display:inline-block}.otherList_31zvf>li+li{border-left:1px solid var(--color-border-thin);margin-left:4px;padding-left:8px}.otherList_31zvf>li>a,.otherList_31zvf>li>button{color:inherit;font-size:.78rem;display:block;transition:var(--transition-button)}.otherList_31zvf>li>a:hover,.otherList_31zvf>li>button:hover{opacity:.6}.icon_29ITP{font-size:1.67rem;color:var(--color-icon)}.badgeWrapper_2QUqk{position:relative}.badge_2GmJG{position:absolute!important;top:6px;left:50%;-webkit-transform:translateX(-50%);transform:translateX(-50%);margin-left:10px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_2m-GX",
	"user": "user_1WQ0b",
	"userImage": "userImage_2lW0e",
	"userName": "userName_1zCar",
	"other": "other_6G8aR",
	"otherList": "otherList_31zvf",
	"icon": "icon_29ITP",
	"badgeWrapper": "badgeWrapper_2QUqk",
	"badge": "badge_2GmJG"
};
module.exports = ___CSS_LOADER_EXPORT___;
