// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".logo_2scOn{width:calc(100% - var(--gutter)*3)}.logo_2scOn.isFullWidth_3z-Aa{width:100%}.logoImage_3aC1i{display:block;margin:0 auto}.icon_2SmkE{font-size:1.33rem;color:#2196f3}.subtitle_2r2zm{color:var(--black-058,rgba(0,0,0,.58));text-align:center;-webkit-font-feature-settings:\"halt\" on;font-feature-settings:\"halt\" on;font-family:Hiragino Sans,sans-serif;font-size:13px;font-style:normal;font-weight:300;line-height:150%;letter-spacing:.4px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "logo_2scOn",
	"isFullWidth": "isFullWidth_3z-Aa",
	"logoImage": "logoImage_3aC1i",
	"icon": "icon_2SmkE",
	"subtitle": "subtitle_2r2zm"
};
module.exports = ___CSS_LOADER_EXPORT___;
