import { Location } from 'vue-router';

export interface InvoiceCardLocation {
  /** 決済カード制作 作成 */
  toInvoiceCardCreateLocation: (params: {projectId: string}) => Location;

  /** 決済カード制作 確認 */
  toInvoiceCardCreateConfirmLocation: (params: {projectId: string}) => Location;

  /** 請求の詳細 */
  toInvoiceCardChargeLocation: (params: { projectId: string, invoiceId: string }) => Location;

  /** 決済カード求詳細（患者側決済前）へのロケーション */
  toInvoiceCardPaymentLocation: (params: { projectId: string, invoiceId: string }) => Location;
}

export const useInvoiceCardLocation = (): InvoiceCardLocation => {
  const toInvoiceCardCreateLocation = (params): Location => {
    return {
      name: 'invoice-card-create-custom',
      params,
    };
  };

  const toInvoiceCardCreateConfirmLocation = (params): Location => {
    return {
      name: 'invoice-card-create-confirm-custom',
      params,
    };
  };

  const toInvoiceCardChargeLocation = (params): Location => {
    return {
      name: 'invoice-card-charge-custom',
      params,
    };
  };

  const toInvoiceCardPaymentLocation = (params): Location => {
    return {
      name: 'invoice-card-charge-payment-custom',
      params,
    };
  };

  return {
    toInvoiceCardCreateLocation,
    toInvoiceCardCreateConfirmLocation,
    toInvoiceCardChargeLocation,
    toInvoiceCardPaymentLocation,
  };
};
