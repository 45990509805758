// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loading_2hce-{position:fixed;top:50%;left:50%;-webkit-transform:translate(-50%,-50%);transform:translate(-50%,-50%);z-index:9999;width:50px;height:50px;background-color:var(--color-white);border-radius:50%;display:flex;justify-content:center;align-items:center;box-shadow:0 0 10px 0 rgba(0,0,0,.2)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading": "loading_2hce-"
};
module.exports = ___CSS_LOADER_EXPORT___;
