// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media only screen and (min-width:600px){.container_2VVJ8{width:100%;max-width:var(--width-content-min);margin-left:auto;margin-right:auto}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_2VVJ8"
};
module.exports = ___CSS_LOADER_EXPORT___;
