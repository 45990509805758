/* eslint-disable no-shadow */
export enum TodoType {
  /** 病院紹介希望条件 */
  HospitalCondition = 'hospitalCondition',

  /** 病院紹介リスト */
  HospitalIntroduction = 'hospitalIntroduction',

  /** 問診 */
  Questionnaire = 'inquiry',

  /** 基本問診 */
  WelcomeQuestionnaire = 'welcomeInquiry',

  /** オンライン医療相談 */
  VideoMeeting = 'videoMeeting',

  /** オンライン医療相談（有料） */
  MedicalConsultationCharged = 'medicalConsultationCharged',

  /** オンライン診療 */
  Telemedicine = 'medicalDiagnosis',

  /** 請求カード */
  Invoice = 'payment',

  /** 専門医チャット */
  SpecialistChat = 'specialistChat',
}

export enum TodoTypeLabel {
  HospitalCondition = '病院紹介希望条件',

  HospitalIntroduction = '病院紹介',

  Questionnaire = '問診',

  WelcomeQuestionnaire = '初回問診',

  VideoMeeting = 'オンライン医療相談',

  MedicalConsultationCharged = 'オンライン医療相談（有料）',

  Telemedicine = 'オンライン診療',

  Invoice = '請求明細',

  SpecialistChat = '専門医チャット',
}

export enum TodoStatus {
  /** [patient] [病院紹介希望条件, 問診] 回答タスク */
  Answer = 'answer',

  /** [doctor] [病院紹介希望条件, 問診] 回答待ち */
  Waiting = 'waiting',

  /** [patient, doctor] [病院紹介リスト, 問診] 回答確認タスク */
  Confirm = 'confirm',

  /** [patient] [病院紹介希望条件] 病院紹介待ち */
  IntoductionWaiting = 'intoductionWaiting',

  /** [doctor] [病院紹介希望条件] 病院紹介タスク */
  Introduction = 'introduction',

  /** [patient, doctor] [オンライン医療相談] 予約済み */
  Reserved = 'reserved',
}

export enum TodoStatusLabel {
  Answer = 'が届きました。',

  Waiting = 'の回答を待っています。',

  Confirm = 'の回答が届きました。',

  IntoductionWaiting = 'を待っています。',

  Introduction = 'が届きました。',

  Reserved = 'の予定があります。',
}
/* eslint-enable */
