import { Location } from 'vue-router';

export interface VideoChatLocation {
  /** F08 オンライン医療相談の予定を作成へのロケーション */
  toVideoChatLocation: (params: { projectId: string}) => Location;
}

export const useVideoChatLocation = (): VideoChatLocation => {
  const toVideoChatLocation = (params): Location => {
    return {
      name: 'video-chat-create-custom',
      params,
    };
  };

  return {
    toVideoChatLocation,
  };
};
