import { UserRole } from '~/components/settings/User';

import {
  Message,
  MessagePreview,
  MessageRequest,
  MessageFile,
  LightGalleryItem,
} from '~/types/Message';

import { MimeType } from '~/components/settings/Message';

const movieMimetype = MimeType.Videos.Mp4;
const imageMimetypes: string[] = Object.values(MimeType.Images);

/** files のみの配列を生成 */
function postListToMessageFiles(postList: (Message | MessagePreview)[]): MessageFile[] {
  const files = postList
    .filter((postData: (Message | MessagePreview)) => !postData.timestamp.deletedAt)
    .reduce((prev, current) => {
      if (current.files && current.files.length > 0) {
        return [...prev, ...current.files];
      }
      return [...prev];
    }, [] as MessageFile[]);

  return files;
}

/**
 * 投稿データ一覧から lightgallery 用の配列を生成する
 * GalleryItem 型を採用したいが video.attributes.preload でエラーとなり動画が動作しなくなるので使えない
 * GalleryItem 型に準拠すると動画が動かない
 */
export function messagePostListToGalleryItemList(postList: (Message | MessagePreview)[]): LightGalleryItem[] {
  // files のみの配列を生成
  const files = postListToMessageFiles(postList);

  // 画像と動画のみに選定
  const filteredList = files.filter(({ mimeType }) => [movieMimetype, ...imageMimetypes].includes(mimeType));

  // lightgallery用のデータに整形する
  const galleryItems = filteredList.map((file) => {
    if (file.mimeType === movieMimetype) {
      return {
        subHtml: file.originalFilename,
        thumb: file.thumbnailUrl || undefined,
        poster: file.thumbnailUrl || undefined,
        video: {
          source: [{ src: file.fileUrl, type: file.mimeType }],
          attributes: { preload: false, controls: true },
        },
      };
    }

    return {
      // subHtml: file.originalFilename,
      thumb: file.fileUrl,
      src: file.fileUrl,
    };
  });

  return galleryItems;
}

/**
 * 画像 or 動画の投稿が lightgallery で表示する際の何番目かを抽出する
 * 通常コメントや画像、動画以外の場合は null を返す
 */
export function messagePostToMediaIndexList(post: Message | MessagePreview, galleryItemList: LightGalleryItem[]): number[] | null {
  if (post.files) {
    const addedPostIdList = post.files.map((file) => ({ ...file, postId: post.id }));
    const onylMediaList = addedPostIdList.filter(({ mimeType }) => [movieMimetype, ...imageMimetypes].includes(mimeType));

    if (onylMediaList.length > 0) {
      // fileUrl はハッシュ化されていてユニークなので index 照合の判定材料とする
      const indexList = onylMediaList.map(({ fileUrl, mimeType }) => {
        if (mimeType === movieMimetype) {
          return galleryItemList.findIndex(({ video }) => video?.source[0].src && fileUrl === video.source[0].src.split('?')[0]);
        }

        return galleryItemList.findIndex(({ thumb }) => thumb && fileUrl === thumb.split('?')[0]);
      });

      return indexList;
    }

    return null;
  }

  return null;
}

/**
 * FileReader で画像データをロードする
 */
function loadFile(file: File): Promise<string | ArrayBuffer> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (e): void => {
      if (e.target && e.target.result) {
        resolve(e.target.result);
      }
    };
    reader.readAsDataURL(file);
  });
}

/**
 * submit直後、データが更新されるまでのプレ投稿データを作る
 */
export async function submitDataToPreviewData(submitData: MessageRequest, userId: string, userRole: UserRole = UserRole.Patient): Promise<MessagePreview> {
  let files: MessageFile[] = [];

  // プレビューなので投稿idはランダムでよし
  const hash = Math.floor(Math.random() * 10000000);

  if (submitData.files) {
    const imagesTypes = Object.values(MimeType.Images) as string[];
    const filesArray: File[] = Object.values(submitData.files);

    const promises: (Promise<string | ArrayBuffer> | null)[] = [];

    filesArray.forEach((file: File) => {
      if (imagesTypes.includes(file.type)) {
        promises.push(loadFile(file));
      } else {
        promises.push(null);
      }
    });

    // base64 の画像生成に時間がかかるので await する
    const images = await Promise.all(promises);

    files = filesArray.map(({ name, size, type }, index) => ({
      id: hash,
      mimeType: type,
      fileUrl: images[index] as string,
      originalFilename: name,
      fileSize: size,
      thumbnailUrl: null,
    }));
  }

  return {
    id: `${hash}`,
    files,
    text: submitData.message || '',
    timestamp: {
      createdAt: '',
      deletedAt: '',
    },
    user: {
      id: userId,
      firstName: '',
      lastName: '',
      icon: '',
      uuid: '',
      role: userRole,
    },
    isMedicalConsulted: false,
    medicalConsultedAt: null,
  };
}

/**
 * コミュニティ投稿用データを作成する
 */
export function formatSubmitData(submitData: MessageRequest): FormData {
  const formData = new FormData();

  if (submitData.message) {
    formData.append('message', submitData.message);
  }

  // 添付ファイルがある場合
  if (submitData.files) {
    submitData.files.forEach((file) => {
      formData.append('files', file);
    });
  }

  // リプライの場合
  if (submitData.replyId) {
    formData.append('replyId', `${submitData.replyId}`);
  }

  return formData;
}
