/**
 * 値が空かどうか判定結果を返す
 * - 数値 0, 論理値 false は空の値としては **扱わない**
 */
export function isEmpty(value: any): boolean {
  const type = Object.prototype.toString.apply(value);
  return value === undefined
    || value === null
    || value === ''
    || Number.isNaN(value)
    || (type === '[object Array]' && value.filter((v) => !isEmpty(v)).length < 1)
    || (type === '[object Object]' && Object.keys(value).length < 1);
}

export function camelCaseToSnakeCase(str: string): string {
  return str.replace(/([A-Z])/g, ($1): string => `_${$1.toLowerCase()}`);
}

export function snakeCaseToCamelCase(str: string): string {
  return str.replace(/(_[a-z])/g, ($1): string => $1.toUpperCase().replace('_', ''));
}

export function camelize<T>(data: T): any {
  const camelizedData = {};
  Object.keys(data).forEach((key) => {
    const value = data[key];
    const valueType = Object.prototype.toString.apply(value);
    const normalizedKey = snakeCaseToCamelCase(key);
    if (valueType === '[object Array]') {
      camelizedData[normalizedKey] = value.map((val) => {
        if (Object.prototype.toString.apply(val) === '[object Object]') {
          return camelize(val);
        }
        return val;
      });
    } else if (valueType === '[object Object]') {
      camelizedData[normalizedKey] = camelize(value);
    } else {
      camelizedData[normalizedKey] = value;
    }
  });
  return camelizedData;
}

export function normalize<T>(query: T) {
  const queryKeys = Object.keys(query);
  const normalizedQuery = {};
  queryKeys
    .filter((key) => {
      const value = query[key];
      return value !== undefined && value !== null && !Number.isNaN(value);
    })
    .forEach((key) => {
      const value = query[key];
      normalizedQuery[camelCaseToSnakeCase(key)] = value;
    });
  return normalizedQuery;
}

export function stringifyQuery<T>(obj: T): string {
  // const querystring = Object.entries(normalize(obj))
  const querystring = Object.entries(obj)
    .map((e) => `${e[0]}=${e[1]}`).join('&');

  return querystring ? `?${querystring}` : '';
}

export function queryStringToObject(searchStr: string): { [key: string]: string } {
  if (!searchStr) return {};
  return searchStr
    .substring(1)
    .split('&')
    .reduce(
      (acc, cur) => {
        acc[cur.split('=')[0]] = cur.split('=')[1];
        return acc;
      },
      {} as { [key: string]: string },
    );
}
