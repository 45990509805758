import { Location } from 'vue-router';
import { EditType } from '~/components/settings/Profile';

export interface ProfileLocation {
  /** F03 マイプロフィールへのロケーション */
  toProfileLocation: () => Location;

  /** F03 マイプロフィール編集へのロケーション */
  toProfileEditLocation: (params: { editType: EditType }) => Location;

  /** ログイン履歴画面へのロケーション */
  toProfileLoginHistory: (param: { userId: string }) => Location;

  /** F03 他者プロフィールへのロケーション */
  toProfileOtherLocation: (params: { uuid: string }) => Location;

  /** F03 メールアドレス変更 仮登録完了画面 */
  toProfileConfirmLocation: () => Location;
}

export const useProfileLocation = (): ProfileLocation => {
  const toProfileLocation = (): Location => {
    return {
      name: 'profile-custom',
    };
  };

  const toProfileEditLocation = (params): Location => {
    return {
      name: 'profile-edit-custom',
      params,
    };
  };

  const toProfileLoginHistory = (params): Location => {
    return {
      name: 'profile-login-history',
      params,
    };
  };

  const toProfileOtherLocation = (params): Location => {
    return {
      name: 'profile-other-custom',
      params,
    };
  };

  const toProfileConfirmLocation = (): Location => {
    return {
      name: 'profile-confirm-custom',
    };
  };

  return {
    toProfileLocation,
    toProfileEditLocation,
    toProfileLoginHistory,
    toProfileOtherLocation,
    toProfileConfirmLocation,
  };
};
