import { Context } from '@nuxt/types';
import {
  Ref,
  ref,
} from '@nuxtjs/composition-api';
import { TodoList } from '~/types/Todo';

export interface TodoState {
  todoList: Ref<TodoList | null>;
  fetchTodoList: () => Promise<void>;
}

export const useTodoState = (context: Context): TodoState => {
  const todoList = ref<TodoList | null>(null);

  const fetchTodoList = async (): Promise<void> => {
    const data = await context.$api.getTodoList();
    todoList.value = data;
  };

  return {
    todoList,
    fetchTodoList,
  };
};
