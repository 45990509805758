import { stringifyQuery } from '~/components/tool/Query';

import { GetMessageResponse, MessageRequest, GetStatusResponse } from '~/types/Message';
import { formatSubmitData } from '~/components/tool/Message';
import { GetChatRoomResponse } from '~/types/Message/getStatus';

export interface ChatApi {
  getChatRooms: (projectId: number | string) => Promise<GetChatRoomResponse>;
  getChatStatus: (projectId: number | string, chatId: number | string) => Promise<any>;
  getDefaultChatStatus: (projectId: number | string) => Promise<GetStatusResponse>;
  getChatMessageByProjectId: (projectId: number | string, chatId: number | string, query) => Promise<GetMessageResponse>;
  postChatMessageByProjectId: (projectId: number | string, chatId: number | string, body: MessageRequest) => Promise<any>;
  removeChatMessageByProjectId: (projectId: string, chatId: number | string, messageId: string) => Promise<void>;
  putChatMessageByProjectId: (projectId: string, chatId: number | string, messageId: string) => Promise<void>;
}

export const useChatApi = (fetch, axios): ChatApi => {
  const getChatRooms = async (projectId: number | string): Promise<GetChatRoomResponse> => {
    const response = await fetch(`/projects/${projectId}/chat/rooms`);
    return response.data;
  };

  const getChatStatus = async (projectId: number | string, chatId: number | string): Promise<any> => {
    const response = await fetch(`/projects/${projectId}/chat/${chatId}/status`);
    return response.data;
  };

  const getDefaultChatStatus = async (projectId: number | string): Promise<GetStatusResponse> => {
    const response = await fetch(`/projects/${projectId}/chat/default`);
    return response.data;
  };

  /**
   * F07 Chatメッセージ取得
   */
  const getChatMessageByProjectId = async (projectId: number | string, chatId: number | string, query): Promise<GetMessageResponse> => {
    const response = await fetch(`/projects/${projectId}/chat/${chatId}/messages${stringifyQuery(query)}`);
    return response.data;
  };

  /**
   * F07 Chatメッセージ投稿
   */
  const postChatMessageByProjectId = async (projectId: number | string, chatId: number | string, body: MessageRequest): Promise<any> => {
    const response = await fetch(`/projects/${projectId}/chat/${chatId}/messages`, formatSubmitData(body));
    return response.data;
  };

  /**
   * F07 Chatメッセージ削除
   */
  const removeChatMessageByProjectId = async (projectId: string, chatId: number | string, messageId: string): Promise<void> => {
    await axios.delete(`/projects/${projectId}/chat/${chatId}/messages/${messageId}`);
  };

  /**
   * F07 Chatメッセージ復活
   */
  const putChatMessageByProjectId = async (projectId: string, chatId: number | string, messageId: string): Promise<void> => {
    await axios.put(`/projects/${projectId}/chat/${chatId}/messages/${messageId}`);
  };

  return {
    getChatRooms,
    getChatStatus,
    getDefaultChatStatus,
    getChatMessageByProjectId,
    postChatMessageByProjectId,
    removeChatMessageByProjectId,
    putChatMessageByProjectId,
  };
};
