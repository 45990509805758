// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_oIul9{width:100%;max-width:var(--width-content-max);margin-left:auto;margin-right:auto;min-height:calc(100vh - var(--height-header))}@media only screen and (min-width:1240px){.container_oIul9{max-width:600px}}.container_oIul9.isBackground_1tew6{background-color:var(--color-white)}@media only screen and (min-width:600px){.container_oIul9.isBackground_1tew6{border-radius:var(--radius);overflow:hidden;margin-top:calc(var(--gutter)/2);margin-bottom:calc(var(--gutter)/2)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_oIul9",
	"isBackground": "isBackground_1tew6"
};
module.exports = ___CSS_LOADER_EXPORT___;
