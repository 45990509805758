import { VideoMeeting } from '~/types/VideoMeeting';
import { InvoiceRequestQueryFormData } from '~/types/Invoice';
import { CreateCardRequestQuery } from '~/types/Timeline';
import { MedicalConsultingInvoice, MedicalConsultingDetail } from '~/types/MedicalConsulting';

export interface MedicalConsultingApi {
  /** オンライン医療相談（有料）の作成 */
  createMedicalConsulting: (projectId: string, requestBody: CreateCardRequestQuery) => Promise<void>

  /** オンライン医療相談（有料）情報の取得 */
  getMedicalConsulting: (projectId: string, medicalConsultingId: string) => Promise<VideoMeeting | null>

  /** オンライン医療相談（有料）詳細情報取得 */
  getMedicalConsultingDetail: (projectId: string, medicalConsultingId: string) => Promise<MedicalConsultingDetail | null>

  /** オンライン医療相談（有料）の入室時情報更新（入退室のログ等を記録） */
  postMedicalConsulting: (projectId: string, medicalConsultingId: string) => Promise<void>

  /** オンライン医療相談（有料）の終了 */
  finishMedicalConsulting: (projectId: string, medicalConsultingId: string) => Promise<void>

  /** オンライン医療相談（有料）の削除 */
  deleteMedicalConsulting: (projectId: string, medicalConsultingId: string) => Promise<void>

  /** 診療明細情報取得 */
  getMedicalConsultingInvoice: (projectId: string, medicalConsultingId: string) => Promise<MedicalConsultingInvoice | null>

  /** オンライン医療相談（有料）明細の送信 */
  postMedicalConsultingInvoice: (projectId: string, medicalConsultingId: string, requestBody: InvoiceRequestQueryFormData) => Promise<void>

  /** 支払いの送信 */
  postMedicalConsultingInvoicePayment: (projectId: string, medicalConsultingId: string) => Promise<void>
}

export const useMedicalConsultingApi = (fetch, axios): MedicalConsultingApi => {
  const createMedicalConsulting = async (projectId: string, requestBody): Promise<void> => {
    await fetch(`/projects/${projectId}/medicalConsultationChargeds`, requestBody);
  };

  const getMedicalConsulting = async (projectId: string, medicalConsultingId: string): Promise<VideoMeeting | null> => {
    try {
      const { data } = await fetch(`/projects/${projectId}/medicalConsultationChargeds/${medicalConsultingId}`);
      return data;
    } catch ({ response }) {
      if ((response as any)?.status !== 401 || (response as any)?.data.statusCode !== 401) {
        throw new Error('オンライン医療相談（有料）情報が取得できませんでした');
      }
      return null;
    }
  };

  const getMedicalConsultingDetail = async (projectId: string, medicalConsultingId: string): Promise<MedicalConsultingDetail | null> => {
    try {
      const { data } = await fetch(`projects/${projectId}/medicalConsultationChargeds/${medicalConsultingId}/medicalConsultationChargedInfo`);
      return data;
    } catch ({ response }) {
      if ((response as any)?.status !== 401 || (response as any)?.data.statusCode !== 401) {
        throw new Error('オンライン医療相談（有料）詳細情報が取得できませんでした');
      }
      return null;
    }
  };

  const postMedicalConsulting = async (projectId: string, medicalConsultingId: string): Promise<void> => {
    await axios.post(`/projects/${projectId}/medicalConsultationChargeds/${medicalConsultingId}`);
  };

  const finishMedicalConsulting = async (projectId: string, medicalConsultingId: string): Promise<void> => {
    await axios.put(`/projects/${projectId}/medicalConsultationChargeds/${medicalConsultingId}`);
  };

  const deleteMedicalConsulting = async (projectId: string, medicalConsultingId: string): Promise<void> => {
    await axios.delete(`/projects/${projectId}/medicalConsultationChargeds/${medicalConsultingId}`);
  };

  const getMedicalConsultingInvoice = async (projectId: string, medicalConsultingId: string): Promise<MedicalConsultingInvoice | null> => {
    try {
      const { data } = await fetch(`/projects/${projectId}/medicalConsultationChargeds/${medicalConsultingId}/payments`);
      return data;
    } catch ({ response }) {
      if ((response as any)?.status !== 401 || (response as any)?.data.statusCode !== 401) {
        throw new Error('診療明細情報が取得できませんでした');
      }
      return null;
    }
  };

  const postMedicalConsultingInvoice = async (projectId: string, medicalConsultingId: string, requestBody: InvoiceRequestQueryFormData): Promise<void> => {
    await fetch(`/projects/${projectId}/medicalConsultationChargeds/${medicalConsultingId}/payments`, requestBody);
  };

  const postMedicalConsultingInvoicePayment = async (projectId: string, medicalConsultingId: string): Promise<void> => {
    const response = await fetch(`/projects/${projectId}/medicalConsultationChargeds/${medicalConsultingId}/paymentSubmit`, {});
    if (response.data.status === 'failed') throw new Error('カード情報の取得に失敗しました。');
  };

  return {
    createMedicalConsulting,
    getMedicalConsulting,
    postMedicalConsulting,
    finishMedicalConsulting,
    deleteMedicalConsulting,
    postMedicalConsultingInvoice,
    getMedicalConsultingDetail,
    getMedicalConsultingInvoice,
    postMedicalConsultingInvoicePayment,
  };
};
