import { StorageName, CardType, CardEventType } from '~/components/settings/Timeline';
import { UserRole } from '~/components/settings/User';
import { Timeline } from '~/types/Timeline';
import { TodoTypeLabel } from '~/components/settings/Todo';

export function getTimelineToStorage(storageName: StorageName): Timeline[] {
  const timeline = sessionStorage.getItem(storageName);
  if (timeline) {
    return JSON.parse(timeline);
  }

  return [];
}

export function setTimelineToStorage(storageName: StorageName, timeline: Timeline[]): void {
  sessionStorage.setItem(storageName, JSON.stringify(timeline));
}

export function removeTimelineToStorage(storageName: StorageName): void {
  sessionStorage.removeItem(storageName);
}

export function toastText(timeline: Timeline, role: UserRole): string {
  const { cardType, eventType } = timeline;

  if (eventType === CardEventType.Deleted) {
    return '';
  }

  // 患者側
  if (role === UserRole.Patient) {
    // 問診
    if (cardType === CardType.Questionnaire) {
      if (eventType === CardEventType.Received) {
        return `${TodoTypeLabel.Questionnaire}が届きました。`;
      }

      return `${TodoTypeLabel.Questionnaire}に回答しました。`;
    }

    // 基本問診
    if (cardType === CardType.WelcomeQuestionnaire) {
      if (eventType === CardEventType.Received) {
        return `${TodoTypeLabel.WelcomeQuestionnaire}が届きました。`;
      }

      return `${TodoTypeLabel.WelcomeQuestionnaire}に回答しました。`;
    }

    // 病院紹介希望条件
    if (cardType === CardType.HospitalCondition) {
      if (eventType === CardEventType.Received) {
        return `${TodoTypeLabel.HospitalCondition}が届きました。`;
      }

      return `${TodoTypeLabel.HospitalCondition}に回答しました。`;
    }

    // 病院紹介
    if (cardType === CardType.HospitalIntroduction) {
      return `${TodoTypeLabel.HospitalIntroduction}が届きました。`;
    }

    // オンライン医療相談
    if (cardType === CardType.VideoMeeting) {
      return `${TodoTypeLabel.VideoMeeting}が届きました。`;
    }

    return '';
  }

  // 医療側
  // 問診
  if (cardType === CardType.Questionnaire) {
    if (eventType === CardEventType.Received) {
      return `${TodoTypeLabel.Questionnaire}を送信しました。`;
    }

    return `${TodoTypeLabel.Questionnaire}の回答が届きました。`;
  }

  // 基本問診
  if (cardType === CardType.WelcomeQuestionnaire) {
    if (eventType === CardEventType.Received) {
      return `${TodoTypeLabel.WelcomeQuestionnaire}を送信しました。`;
    }

    return `${TodoTypeLabel.WelcomeQuestionnaire}の回答が届きました。`;
  }

  // 病院紹介希望条件
  if (cardType === CardType.HospitalCondition) {
    if (eventType === CardEventType.Received) {
      return `${TodoTypeLabel.HospitalCondition}を送信しました。`;
    }

    return `${TodoTypeLabel.HospitalCondition}の回答が届きました。`;
  }

  // 病院紹介
  if (cardType === CardType.HospitalIntroduction) {
    return `${TodoTypeLabel.HospitalIntroduction}を送信しました。`;
  }

  // オンライン医療相談
  if (cardType === CardType.VideoMeeting) {
    return `${TodoTypeLabel.VideoMeeting}を作成しました。`;
  }

  return '';
}
