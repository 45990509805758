import { Context } from '@nuxt/types';

import { RootState, useRootState } from './root';
import { ProjectState, useProjectState } from './project';
import { TelemedicineState, useTelemedicineState } from './telemedicine';
import { MedicalConsultingState, useMedicalConsultingState } from './medicalConsulting';
import { SpecialistChatState, useSpecialistChatState } from './specialistChat';
import { TodoState, useTodoState } from './todo';
import { ProfileState, useProfileState } from './profile';
import { HospitalIntroductionChargeState, useHospitalIntroductionChargeState } from './hospitalIntroductionCharge';
import { InvoiceCardState, useInvoiceCardState } from './invoiceCard';
import { SearchBarState, useSearchBarState } from './searchBar';
import { DoctorHeaderState, useDoctorHeaderState } from './doctorHeader';

export type GlobalState = {
  init: () => void,
}
& RootState
& ProjectState
& TelemedicineState
& MedicalConsultingState
& SpecialistChatState
& TodoState
& ProfileState
& HospitalIntroductionChargeState
& InvoiceCardState
& SearchBarState
& DoctorHeaderState

export const globalState = (context: Context): GlobalState => {
  const rootState = useRootState();
  const projectState = useProjectState(context);
  const todoState = useTodoState(context);
  const telemedicineState = useTelemedicineState();
  const medicalConsultingState = useMedicalConsultingState();
  const specialistChatState = useSpecialistChatState();
  const profileState = useProfileState(context);
  const hospitalIntroductionChargeState = useHospitalIntroductionChargeState();
  const invoiceCardState = useInvoiceCardState();
  const searchBarState = useSearchBarState();
  const doctorHeaderState = useDoctorHeaderState();

  const init = async (): Promise<void> => {
    projectState.initProject();
    rootState.initRootState();
  };

  return {
    init,
    ...rootState,
    ...projectState,
    ...todoState,
    ...telemedicineState,
    ...medicalConsultingState,
    ...specialistChatState,
    ...profileState,
    ...hospitalIntroductionChargeState,
    ...invoiceCardState,
    ...searchBarState,
    ...doctorHeaderState,
  };
};
