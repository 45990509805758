import { Location } from 'vue-router';

export interface HospitalConditionLocation {
  /** F09 [only patient] 病院紹介-希望条件入力へのロケーション */
  toHospitalRequirementFormLocation: (params: { projectId: string, hospitalConditionId: string }) => Location;

  /** F09 [only patient] 病院紹介-希望条件入力-確認へのロケーション */
  toHospitalRequirementFormConfirmLocation: (params: { projectId: string, hospitalConditionId: string }) => Location;

  /** F09 病院紹介 希望条件 回答結果へのロケーション */
  toHospitalRequirementResultLocation: (params: { projectId: string, hospitalConditionId: string }) => Location;

  /** F09 [only doctor] 病院紹介 希望条件 選択リスト */
  toHospitalRequirementListLocation: (params: { projectId: string }) => Location;

  /** F09 [only doctor] 病院紹介 希望条件 選択リスト 確認 */
  toHospitalRequirementListConfirmLocation: (params: { projectId: string, templateId: string }) => Location;

  /** F06 [only doctor] 病院紹介-希望条件-確認へのロケーション */
  toHospitalRequirementDetailLocation: (params: { projectId: string, hospitalConditionId: string }) => Location;

  /** F09 [only doctor] 病院紹介 入力 */
  toHospitalIntroductionFormLocation: (params: { projectId: string, hospitalConditionId: string }) => Location;

  /** F09 [only doctor] 病院紹介 確認 */
  toHospitalIntroductionFormConfirmLocation: (params: { projectId: string, hospitalConditionId: string }) => Location;

  /** F09 病院紹介 結果 */
  toHospitalIntroductionResultLocation: (params: { projectId: string, hospitalIntroductionId: string }) => Location;

  /** F09 病院紹介 履歴 */
  toHospitalIntroductionHistoryLocation: (params: { projectId: string, hospitalIntroductionId: string }) => Location;

  /** 決済カード 作成 */
  toInvoiceCardCreateLocation: (params: { projectId: string}) => Location;
}

export const useHospitalConditionLocation = (): HospitalConditionLocation => {
  const toHospitalRequirementFormLocation = (params): Location => {
    return {
      name: 'hospital-requirement-form-custom',
      params,
    };
  };

  const toHospitalRequirementFormConfirmLocation = (params): Location => {
    return {
      name: 'hospital-requirement-form-confirm-custom',
      params,
    };
  };

  const toHospitalRequirementResultLocation = (params): Location => {
    return {
      name: 'hospital-requirement-result-custom',
      params,
    };
  };

  const toHospitalRequirementListLocation = (params): Location => {
    return {
      name: 'hospital-requirement-list-custom',
      params,
    };
  };

  const toHospitalRequirementListConfirmLocation = (params): Location => {
    return {
      name: 'hospital-requirement-list-confirm-custom',
      params,
    };
  };

  const toHospitalRequirementDetailLocation = (params): Location => {
    return {
      name: 'hospital-requirement-detail-custom',
      params,
    };
  };

  const toHospitalIntroductionFormLocation = (params): Location => {
    return {
      name: 'hospital-introduction-form-custom',
      params,
    };
  };

  const toHospitalIntroductionFormConfirmLocation = (params): Location => {
    return {
      name: 'hospital-introduction-form-confirm-custom',
      params,
    };
  };

  const toHospitalIntroductionResultLocation = (params): Location => {
    return {
      name: 'hospital-introduction-result-custom',
      params,
    };
  };

  const toHospitalIntroductionHistoryLocation = (params): Location => {
    return {
      name: 'hospital-introduction-history-custom',
      params,
    };
  };

  const toInvoiceCardCreateLocation = (params): Location => {
    return {
      name: 'invoice-card-create-custom',
      params,
    };
  };

  return {
    toHospitalRequirementFormLocation,
    toHospitalRequirementFormConfirmLocation,
    toHospitalRequirementResultLocation,
    toHospitalRequirementListLocation,
    toHospitalRequirementListConfirmLocation,
    toHospitalRequirementDetailLocation,
    toHospitalIntroductionFormLocation,
    toHospitalIntroductionFormConfirmLocation,
    toHospitalIntroductionResultLocation,
    toHospitalIntroductionHistoryLocation,
    toInvoiceCardCreateLocation,
  };
};
