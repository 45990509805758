import { Location } from 'vue-router';

import { ModuleName } from '~/components/settings/Timeline';

export interface PaymentLocation {
  /** P60 決済 */
  toPaymentLocation: (
    params: { projectId: string, paymentId: string},
    query: {
      paymentType: ModuleName;
      // 病院紹介のみapiの構成が違うため、URLに各種IDを含むようにする
      hospitalConditionId?: string;
      hospitalIntroductionId?: string;
    }
  ) => Location;
}

export const usePaymentLocation = (): PaymentLocation => {
  const toPaymentLocation = (params, query): Location => {
    return {
      name: 'payment-custom',
      params,
      query,
    };
  };

  return {
    toPaymentLocation,
  };
};
