// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_1qkiq{display:flex;flex-direction:column;height:100%}.user_1oyXO{border-bottom:1px solid var(--color-border-thin);padding:var(--gutter) var(--gap);display:flex;color:inherit!important;gap:15px}.userImage_1LHZj{width:50px;height:50px}.userName_3vAA5{font-size:1.11rem}.userName_3vAA5>span{display:block;font-size:.72rem;color:var(--v-anchor-base);font-weight:400}.navigation_OrAEd{display:none}@media only screen and (min-width:1240px){.navigation_OrAEd{display:block}}.other_18KHC{margin-top:auto;border-top:1px solid var(--color-border-thin);padding:var(--gutter) var(--gap)}.otherList_29B0P{padding:0!important}.otherList_29B0P>li{display:inline-block}.otherList_29B0P>li+li{border-left:1px solid var(--color-border-thin);margin-left:4px;padding-left:8px}.otherList_29B0P>li>a,.otherList_29B0P>li>button{color:inherit;font-size:.78rem;display:block;transition:var(--transition-button)}.otherList_29B0P>li>a:hover,.otherList_29B0P>li>button:hover{opacity:.6}.icon_1cYoC{font-size:1.67rem;color:var(--color-icon)}.badgeWrapper_15xe8{position:relative}.badge_1tjDA{position:absolute!important;top:6px;left:50%;-webkit-transform:translateX(-50%);transform:translateX(-50%);margin-left:10px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_1qkiq",
	"user": "user_1oyXO",
	"userImage": "userImage_1LHZj",
	"userName": "userName_3vAA5",
	"navigation": "navigation_OrAEd",
	"other": "other_18KHC",
	"otherList": "otherList_29B0P",
	"icon": "icon_1cYoC",
	"badgeWrapper": "badgeWrapper_15xe8",
	"badge": "badge_1tjDA"
};
module.exports = ___CSS_LOADER_EXPORT___;
