// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loading_Qn9IH{display:inline-block;position:relative}.loading_Qn9IH:before{content:\"\";display:block;border:2px solid var(--color-border);border-left:2px solid transparent;-webkit-animation:loading_Qn9IH 1s linear infinite;animation:loading_Qn9IH 1s linear infinite;border-radius:50%;width:100%;height:100%;position:absolite}.loading_Qn9IH[data-color=primary]:before{border-color:var(--v-primary-base);border-left-color:transparent}@-webkit-keyframes loading_Qn9IH{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}@keyframes loading_Qn9IH{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}to{-webkit-transform:rotate(1turn);transform:rotate(1turn)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading": "loading_Qn9IH"
};
module.exports = ___CSS_LOADER_EXPORT___;
