import Vue from 'vue';
import createAuth0Client from '@auth0/auth0-spa-js';
import * as Sentry from '@sentry/vue';
import { queryStringToObject } from '~/components/tool/Query';
import { UserRole } from '../components/settings/User';

/** Define a default action to perform after authentication */
// const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);

let instance;

/** Returns the current instance of the SDK */
export const getInstance = () => instance;

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
/* eslint-disable prefer-const, @typescript-eslint/naming-convention */
export const useAuth0 = (
  {
    onRedirectCallback,
    ...options
  },
  context,
) => {
  if (instance) return instance;

  // The 'instance' is simply a Vue object
  instance = new Vue({
    data() {
      return {
        loading: true,
        isAuthenticated: null,
        user: {},
        auth0Client: null,
        popupOpen: false,
        error: null,
        token: null,
      };
    },
    /** Use this lifecycle method to instantiate the SDK client */
    async created(): Promise<void> {
      if (process.env.DEVELOPMENT_TYPE === 'static') {
        setTimeout(() => {
          this.isAuthenticated = true;
          this.token = 'test';
          this.getMe();
        }, 1000);
        setTimeout(() => {
          this.loading = false;
        }, 2000);
        return;
      }
      // Create a new instance of the SDK client using members of the given options object
      this.auth0Client = await createAuth0Client({
        domain: options.domain,
        client_id: options.clientId,
        audience: options.audience,
        // scope: options.scope,
        redirect_uri: options.redirectUri,
        useRefreshTokens: true, // トークン更新をリフレッシュトークンで行う
        cacheLocation: 'localstorage', // 格納先はlocalstorage（ページリロード時も保持されるため高速）
      });

      try {
        // If the user is returning to the app after authentication..
        if (
          window.location.search.includes('code=')
          && window.location.search.includes('state=')
        ) {
          // handle the redirect and retrieve tokens
          const { appState } = await this.auth0Client.handleRedirectCallback();

          this.error = null;

          // Notify subscribers that the redirect callback has happened, passing the appState
          // (useful for retrieving any pre-authentication state)
          onRedirectCallback(appState);
        }
      } catch (e) {
        this.error = e;
      } finally {
        // Initialize our internal authentication state
        this.isAuthenticated = await this.auth0Client.isAuthenticated();

        if (this.isAuthenticated) {
          this.token = await this.auth0Client.getTokenSilently();

          await this.getMe();
          Sentry.setTag('userid', this.user.id);
          Sentry.setTag('email', this.user.mailaddress);

          this.loading = false;

          localStorage.setItem('role', this.user.role);
        } else if (
          // local 開発時はログインできるので loginWithRedirect 可
          process.env.NODE_ENV === 'development'
          // dstation.thesss.net へのアップ時以外は loginWithRedirect 可
          || process.env.DEVELOPMENT_TYPE !== 'static'
        ) {
          // ログイン不必要ページは loginWithRedirect しない
          if (!context.route.meta[0]?.permissions?.includes(UserRole.Guest)) {
            let role = options.redirectUri.split('role=')[1];
            role = role ? role.split('&')[0] : '';

            if (
              (!!role || !!localStorage.getItem('role'))
              && (role === UserRole.Doctor || localStorage.getItem('role') !== UserRole.Patient)
            ) {
              context.redirect(`/login?role=${UserRole.Doctor}`);
            } else {
              context.redirect('/login');
            }
          }
        }
      }
    },
    methods: {
      /** Handles the callback when logging in using a redirect */
      async handleRedirectCallback(): Promise<void> {
        this.loading = true;
        try {
          await this.auth0Client.handleRedirectCallback();
          this.user = await this.auth0Client.getUser();
          this.isAuthenticated = true;
          this.error = null;
        } catch (e) {
          this.error = e;
        } finally {
          this.loading = false;
        }
      },

      /** Authenticates the user using the redirect method */
      loginWithRedirect(o) {
        return this.auth0Client.loginWithRedirect(o);
      },

      /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
      getTokenSilently(o) {
        return this.auth0Client.getTokenSilently(o);
      },

      /** Logs the user out and removes their session on the authorization server */
      logout(o) {
        return this.auth0Client.logout(o);
      },

      async getMe(): Promise<void> {
        // eslint-disable-next-line no-async-promise-executor
        const user = await context.$api.getProfile();
        this.user = user;
      },

      /** アクセストークンを更新する。キャッシュが残っている場合はキャッシュを利用する */
      async updateTokenWithCache(): Promise<string> {
        this.token = await this.auth0Client.getTokenSilently({
          ignoreCache: false,
        });
        return this.token;
      },
      /** アクセストークンを更新する。キャッシュが残っている場合も強制的に更新する */
      async updateToken(): Promise<void> {
        this.token = await this.auth0Client.getTokenSilently({
          ignoreCache: true,
        });
      },
    },
  });

  return instance;
};

export default async function (context, inject) {
  let redirectUri = window.location.origin;

  if (window && window.location.search) {
    const prams = queryStringToObject(window.location.search);

    if (prams?.role && prams?.role !== UserRole.Patient) {
      redirectUri += `?role=${UserRole.Doctor}`;
    }
  }

  const options = {
    domain: context.env.AUTH0_DOMAIN,
    clientId: context.env.AUTH0_CLIENT_ID,
    audience: context.env.AUTH0_AUDIENCE,
    redirectUri,
    onRedirectCallback: (appState) => {
      context.app.router.push(
        appState && appState.targetUrl
          ? appState.targetUrl
          : window.location.pathname,
      );
    },
  };

  const $auth0 = await useAuth0({ ...options }, context);
  inject('auth0', $auth0);
}
