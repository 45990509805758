import { Location } from 'vue-router';

export interface SpecialistChatLocation {
  /** P-80 専門医チャット の作成へのロケーション */
  toSpecialistChatCreateLocation: (params: { projectId: string }) => Location;

  /** P-81 専門医チャットへのロケーション */
  toSpecialistChatLocation: (params: { projectId: string, specialistChatId: string }) => Location;

  /** P-82 専門医チャット 請求 作成 */
  toSpecialistChatChargeCreateLocation: (params: {projectId: string, specialistChatId: string}) => Location;

  /** P-83 専門医チャット 請求 確認 */
  toSpecialistChatChargeConfirmLocation: (params: {projectId: string, specialistChatId: string}) => Location;

  /** P-84-1, P-84-2, P-84-4 専門医チャット 請求の詳細 */
  toSpecialistChatChargeLocation: (params: { projectId: string, specialistChatId: string }) => Location;

  /** P-84-3 専門医チャット 請求詳細（患者側決済前）へのロケーション */
  toSpecialistChatChargePaymentLocation: (params: { projectId: string, specialistChatId: string }) => Location;
}

export const useSpecialistChatLocation = (): SpecialistChatLocation => {
  const toSpecialistChatCreateLocation = (params): Location => {
    return {
      name: 'specialist-chat-create-custom',
      params,
    };
  };

  const toSpecialistChatLocation = (params): Location => {
    return {
      name: 'specialist-chat-custom',
      params,
    };
  };

  const toSpecialistChatChargeCreateLocation = (params): Location => {
    return {
      name: 'specialist-chat-charge-create-custom',
      params,
    };
  };

  const toSpecialistChatChargeConfirmLocation = (params): Location => {
    return {
      name: 'specialist-chat-charge-create-confirm-custom',
      params,
    };
  };

  const toSpecialistChatChargeLocation = (params): Location => {
    return {
      name: 'specialist-chat-charge-custom',
      params,
    };
  };

  const toSpecialistChatChargePaymentLocation = (params): Location => {
    return {
      name: 'specialist-chat-charge-payment-custom',
      params,
    };
  };

  return {
    toSpecialistChatChargeCreateLocation,
    toSpecialistChatLocation,
    toSpecialistChatChargeConfirmLocation,
    toSpecialistChatCreateLocation,
    toSpecialistChatChargeLocation,
    toSpecialistChatChargePaymentLocation,
  };
};
