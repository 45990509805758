import { stringifyQuery } from '~/components/tool/Query';
import { formatSubmitData } from '~/components/tool/Message';
import { CreateCardRequestQuery } from '~/types/Timeline';
import { SpecialistChatInvoice, SpecialistChatDetail } from '~/types/SpecialistChat';

import { GetMessageResponse, MessageRequest } from '~/types/Message';
import { InvoiceRequestQueryFormData } from '~/types/Invoice';

export interface SpecialistChatApi {
  /** 専門医チャットの作成 */
  createSpecialistChat: (projectId: string, requestBody: CreateCardRequestQuery) => Promise<void>

  /** 専門医チャット詳細情報取得 */
  getSpecialistChatDetail: (projectId: string, medicalConsultingId: string) => Promise<SpecialistChatDetail | null>

  /** 専門医チャットメッセージ取得 */
  getSpecialistChatMessageByProjectId: (projectId: number | string, specialistChatId: number | string, query) => Promise<GetMessageResponse>;

  /** 専門医チャットメッセージ投稿 */
  postSpecialistChatMessageByProjectId: (projectId: number | string, specialistChatId: number | string, body: MessageRequest) => Promise<any>;

  /** 専門医チャットメッセージ削除 */
  removeSpecialistChatMessageByProjectId: (projectId: string, specialistChatId: number | string, messageId: string) => Promise<void>;

  /** 専門医チャットメッセージ復活 */
  putSpecialistChatMessageByProjectId: (projectId: string, specialistChatId: number | string, messageId: string) => Promise<void>;

  /** 専門医チャット の終了 */
  finishSpecialistChat: (projectId: string, specialistChatId: string) => Promise<void>

  /** 専門医チャット の削除 */
  deleteSpecialistChat: (projectId: string, specialistChatId: string) => Promise<void>

  /** 診療明細情報取得 */
  getSpecialistChatInvoice: (projectId: string, specialistChatId: string) => Promise<SpecialistChatInvoice | null>

  /** 専門医チャット 明細の送信 */
  postSpecialistChatInvoice: (projectId: string, specialistChatId: string, requestBody: InvoiceRequestQueryFormData) => Promise<void>

  /** 支払いの送信 */
  postSpecialistChatInvoicePayment: (projectId: string, specialistChatId: string) => Promise<void>
}

export const useSpecialistChatApi = (fetch, axios): SpecialistChatApi => {
  const createSpecialistChat = async (projectId: string, requestBody): Promise<void> => {
    await fetch(`/projects/${projectId}/specialistChat`, requestBody);
  };

  const getSpecialistChatDetail = async (projectId: string, specialistChatId: string): Promise<SpecialistChatDetail | null> => {
    try {
      const { data } = await fetch(`/projects/${projectId}/specialistChat/${specialistChatId}`);
      return data;
    } catch ({ response }) {
      if ((response as any)?.status !== 401 || (response as any)?.data.statusCode !== 401) {
        throw new Error('専門医チャット詳細情報が取得できませんでした');
      }
      return null;
    }
  };

  const getSpecialistChatMessageByProjectId = async (projectId, specialistChatId, query): Promise<GetMessageResponse> => {
    const response = await fetch(`/projects/${projectId}/specialistChat/${specialistChatId}/messages${stringifyQuery(query)}`);

    return response.data;
  };

  const postSpecialistChatMessageByProjectId = async (projectId, specialistChatId, body: MessageRequest): Promise<any> => {
    const response = await fetch(`/projects/${projectId}/specialistChat/${specialistChatId}/messages`, formatSubmitData(body));

    return response.data;
  };

  const removeSpecialistChatMessageByProjectId = async (projectId, specialistChatId, messageId): Promise<void> => {
    await axios.delete(`/projects/${projectId}/specialistChat/${specialistChatId}/messages/${messageId}`);
  };

  const putSpecialistChatMessageByProjectId = async (projectId, specialistChatId, messageId): Promise<void> => {
    await axios.put(`/projects/${projectId}/specialistChat/${specialistChatId}/messages/${messageId}`);
  };

  const finishSpecialistChat = async (projectId: string, specialistChatId: string): Promise<void> => {
    await axios.put(`/projects/${projectId}/specialistChat/${specialistChatId}`);
  };

  const deleteSpecialistChat = async (projectId: string, specialistChatId: string): Promise<void> => {
    await axios.delete(`/projects/${projectId}/specialistChat/${specialistChatId}`);
  };

  const getSpecialistChatInvoice = async (projectId: string, specialistChatId: string): Promise<SpecialistChatInvoice | null> => {
    try {
      const { data } = await fetch(`/projects/${projectId}/specialistChat/${specialistChatId}/payments`);
      return data;
    } catch ({ response }) {
      if ((response as any)?.status !== 401 || (response as any)?.data.statusCode !== 401) {
        throw new Error('診療明細情報が取得できませんでした');
      }
      return null;
    }
  };

  const postSpecialistChatInvoice = async (projectId: string, specialistChatId: string, requestBody: InvoiceRequestQueryFormData): Promise<void> => {
    await fetch(`/projects/${projectId}/specialistChat/${specialistChatId}/payments`, requestBody);
  };

  const postSpecialistChatInvoicePayment = async (projectId: string, specialistChatId: string): Promise<void> => {
    const response = await fetch(`/projects/${projectId}/specialistChat/${specialistChatId}/paymentSubmit`, {});
    if (response.data.status === 'failed') throw new Error('カード情報の取得に失敗しました。');
  };

  return {
    createSpecialistChat,
    getSpecialistChatDetail,
    getSpecialistChatMessageByProjectId,
    postSpecialistChatMessageByProjectId,
    removeSpecialistChatMessageByProjectId,
    putSpecialistChatMessageByProjectId,
    finishSpecialistChat,
    deleteSpecialistChat,
    getSpecialistChatInvoice,
    postSpecialistChatInvoice,
    postSpecialistChatInvoicePayment,
  };
};
