// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".searchIcon_vp5tW{width:48px;height:40px;padding:8px,12px,8px,12px;border-radius:4px;gap:6px;background-color:#0078dc;display:flex;justify-content:center;align-items:center;cursor:pointer}.card_z57WG{height:70px}.emptyValue_-CYvX{color:rgba(0,0,0,.6)}.clearBtn_3-x6V{color:var(--custom-primary,#0078dc);font-size:16px;letter-spacing:.3px;padding-left:16px;font-weight:600;gap:16px;cursor:pointer}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchIcon": "searchIcon_vp5tW",
	"card": "card_z57WG",
	"emptyValue": "emptyValue_-CYvX",
	"clearBtn": "clearBtn_3-x6V"
};
module.exports = ___CSS_LOADER_EXPORT___;
