import { Location } from 'vue-router';

export interface MedicalConsultingLocation {
  /** P-70 オンライン医療相談（有料）の作成へのロケーション */
  toMedicalConsultingCreateLocation: (params: {projectId: string}) => Location;

  /** P-72 オンライン医療相談（有料）請求 作成 */
  toMedicalConsultingChargeCreateLocation: (params: {projectId: string, medicalConsultingId: string}) => Location;

  /** P-73 オンライン医療相談（有料）請求 確認 */
  toMedicalConsultingChargeConfirmLocation: (params: {projectId: string, medicalConsultingId: string}) => Location;

  /** P-74-1, P-74-2, P-74-4 オンライン医療相談（有料）請求の詳細 */
  toMedicalConsultingChargeLocation: (params: { projectId: string, medicalConsultingId: string }) => Location;

  /** P-74-3 オンライン医療相談（有料）請求詳細（患者側決済前）へのロケーション */
  toMedicalConsultingChargePaymentLocation: (params: { projectId: string, medicalConsultingId: string }) => Location;

  /** オンライン診療予約へのロケーション */
  toMedicalConsultingReserveLocation: (params: { projectId?: string, questionnaireId?: string }) => Location;
}

export const useMedicalConsultingLocation = (): MedicalConsultingLocation => {
  const toMedicalConsultingChargeCreateLocation = (params): Location => {
    return {
      name: 'medical-consulting-charge-create-custom',
      params,
    };
  };

  const toMedicalConsultingChargeConfirmLocation = (params): Location => {
    return {
      name: 'medical-consulting-charge-create-confirm-custom',
      params,
    };
  };

  const toMedicalConsultingCreateLocation = (params): Location => {
    return {
      name: 'medical-consulting-create-custom',
      params,
    };
  };

  const toMedicalConsultingChargeLocation = (params): Location => {
    return {
      name: 'medical-consulting-charge-custom',
      params,
    };
  };

  const toMedicalConsultingChargePaymentLocation = (params): Location => {
    return {
      name: 'medical-consulting-charge-payment-custom',
      params,
    };
  };

  const toMedicalConsultingReserveLocation = (params): Location => {
    return {
      name: 'medical-consulting-reserve-custom',
      params,
    };
  };

  return {
    toMedicalConsultingChargeCreateLocation,
    toMedicalConsultingChargeConfirmLocation,
    toMedicalConsultingCreateLocation,
    toMedicalConsultingChargeLocation,
    toMedicalConsultingChargePaymentLocation,
    toMedicalConsultingReserveLocation,
  };
};
