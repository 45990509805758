import { QuestionTemplateListItem, QuestionTemplate } from '~/types/QuestionTemplate';
import { InvoiceRequestQueryFormData } from '~/types/Invoice';
import { HospitalConditionResponse, HospitalConditionRequestQuery, HospitalConditionSelectRequestQuery } from '~/types/HospitalCondition';
import {
  HospitalIntroductionList, HospitalIntroductionRequestQuery, HospitalIntroductionListItem, HospitalIntroductionInvoice,
} from '~/types/HospitalIntroduction';

export interface HospitalConditionApi {
  /** 病院紹介希望条件テンプレート一覧の取得 */
  getHospitalConditionTemplates: () => Promise<QuestionTemplateListItem[]>;

  /** 病院紹介希望条件の取得 */
  getHospitalConditionBaseTemplate: (templateId: string) => Promise<QuestionTemplate>;

  /** F09 病院紹介希望条件テンプレート、および回答結果の取得 */
  getHospitalConditionTemplate: (projectId: string, hospitalConditionId: string) => Promise<HospitalConditionResponse>;

  /** 病院紹介希望条件テンプレートの送信 */
  postHospitalConditionTemplate: (projectId: string, requestBody: HospitalConditionSelectRequestQuery) => Promise<void>;

  /** 病院紹介希望条件回答の送信 */
  postHospitalCondition: (projectId: string, hospitalConditionId: string, requestBody: HospitalConditionRequestQuery) =>Promise<HospitalConditionResponse>;

  /** 病院紹介希望条件 削除 */
  removeHospitalCondition: (projectId: string, hospitalConditionId: string) => Promise<void>;

  /** 紹介済み病院一覧の取得 */
  getHospitalIntroductions: (projectId: string) => Promise<HospitalIntroductionList>;

  /** 病院紹介（有料）結果の取得 */
  getHospitalIntroductionCharge: (projectId: string, hospitalConditionId: string) =>Promise<HospitalIntroductionListItem>;

  /** 病院紹介結果の取得 */
  getHospitalIntroduction: (projectId: string, hospitalIntroductionId: string) =>Promise<HospitalIntroductionListItem>;

  /** 病院紹介の送信 */
  postHospitalIntroduction: (projectId: string, requestBody: HospitalIntroductionRequestQuery) =>Promise<HospitalIntroductionListItem>;

  /** 病院紹介 削除 */
  removeHospitalIntroduction: (projectId: string, hospitalConditionId: string) => Promise<void>;

  /** 診療明細情報取得 */
  getHospitalIntroductionInvoice: (projectId: string, hospitalIntroductionPaymentId: string) => Promise<HospitalIntroductionInvoice | null>

  /** 病院紹介（有料） 請求書作成 */
  postHospitalIntroductionInvoice: (projectId: string, hospitalConditionId: string, hospitalIntroductionId: string, requestBody: InvoiceRequestQueryFormData) =>Promise<void>;

  /** 支払いの送信 */
  postHospitalIntroductionInvoicePayment: (projectId: string, hospitalConditionId: string, hospitalIntroductionId: string) => Promise<void>
}

export const useHospitalConditionApi = (fetch, axios): HospitalConditionApi => {
  const getHospitalConditionTemplates = async (): Promise<QuestionTemplateListItem[]> => {
    const response = await fetch('/hospitalConditionTemplates');
    return response.data.data;
  };

  const getHospitalConditionBaseTemplate = async (templateId: string): Promise<QuestionTemplate> => {
    const response = await fetch(`/hospitalConditionTemplates/${templateId}`);
    return response.data.data;
  };

  const getHospitalConditionTemplate = async (projectId: string, hospitalConditionId: string): Promise<HospitalConditionResponse> => {
    const response = await fetch(`/projects/${projectId}/hospitalConditions/${hospitalConditionId}`);

    return response.data;
  };

  const postHospitalConditionTemplate = async (projectId: string, requestBody: HospitalConditionSelectRequestQuery): Promise<void> => {
    await fetch(`/projects/${projectId}/hospitalConditions`, requestBody);
  };

  const postHospitalCondition = async (projectId: string, hospitalConditionId: string, requestBody: HospitalConditionRequestQuery): Promise<HospitalConditionResponse> => {
    const response = await fetch(`/projects/${projectId}/hospitalConditions/${hospitalConditionId}`, requestBody);

    return response.data;
  };

  const removeHospitalCondition = async (projectId: string, hospitalConditionId: string): Promise<void> => {
    await axios.delete(`/projects/${projectId}/hospitalConditions/${hospitalConditionId}`);
  };

  const getHospitalIntroductions = async (projectId: string): Promise<HospitalIntroductionList> => {
    const response = await fetch(`/projects/${projectId}/hospitalIntroductions`);
    return response.data;
  };

  const getHospitalIntroduction = async (projectId: string, hospitalIntroductionId: string): Promise<HospitalIntroductionListItem> => {
    const response = await fetch(`/projects/${projectId}/hospitalIntroductions/${hospitalIntroductionId}`);
    return response.data;
  };

  const getHospitalIntroductionCharge = async (projectId: string, hospitalConditionId: string): Promise<HospitalIntroductionListItem> => {
    const response = await fetch(`/projects/${projectId}/hospitalIntroductions/paid/${hospitalConditionId}`);
    return response.data;
  };

  const postHospitalIntroduction = async (projectId: string, requestBody: HospitalIntroductionRequestQuery): Promise<HospitalIntroductionListItem> => {
    const response = await fetch(`/projects/${projectId}/hospitalIntroductions`, requestBody);
    return response.data;
  };

  const removeHospitalIntroduction = async (projectId: string, hospitalConditionId: string): Promise<void> => {
    await axios.delete(`/projects/${projectId}/hospitalIntroductions/${hospitalConditionId}`);
  };

  const postHospitalIntroductionInvoice = async (projectId, hospitalConditionId, hospitalIntroductionId, requestBody): Promise<void> => {
    await fetch(`/projects/${projectId}/hospitalIntroductionCharged/${hospitalConditionId}/${hospitalIntroductionId}/payment`, requestBody);
  };

  const getHospitalIntroductionInvoice = async (projectId: string, hospitalIntroductionPaymentId: string): Promise<HospitalIntroductionInvoice | null> => {
    const { data } = await fetch(`/projects/${projectId}/hospitalIntroductionCharged/${hospitalIntroductionPaymentId}/payment`);
    return data;
  };

  const postHospitalIntroductionInvoicePayment = async (projectId: string, hospitalConditionId: string, hospitalIntroductionId: string): Promise<void> => {
    const response = await fetch(`/projects/${projectId}/hospitalIntroductionCharged/${hospitalConditionId}/${hospitalIntroductionId}/paymentSubmit`, {});
    if (response.data.status === 'failed') throw new Error('カード情報の取得に失敗しました。');
  };

  return {
    getHospitalConditionTemplates,
    getHospitalConditionTemplate,
    getHospitalConditionBaseTemplate,
    postHospitalConditionTemplate,
    postHospitalCondition,
    removeHospitalCondition,
    getHospitalIntroductions,
    getHospitalIntroductionCharge,
    getHospitalIntroduction,
    postHospitalIntroduction,
    removeHospitalIntroduction,
    postHospitalIntroductionInvoice,
    getHospitalIntroductionInvoice,
    postHospitalIntroductionInvoicePayment,
  };
};
