import { MedicalTeam } from '~/types/Project';

export interface MedicalTeamApi {
  getMedicalTeamsByProjectIdAndMedicalTeamId: (projectId: number | string, medicalTeamId: number | string) => Promise<MedicalTeam>;
}

export const useMedicalTeamApi = (fetch): MedicalTeamApi => {
  /** projectId と medicalTeamId から特定の医療チームを取得する */
  const getMedicalTeamsByProjectIdAndMedicalTeamId = async (projectId: number | string, medicalTeamId: number | string): Promise<MedicalTeam> => {
    const medicalTeams = await fetch(`/projects/${projectId}/medicalTeams/${medicalTeamId}`);

    return medicalTeams.data;
  };

  return {
    getMedicalTeamsByProjectIdAndMedicalTeamId,
  };
};
