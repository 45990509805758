import { Location } from 'vue-router';

export interface TelemedicineLocation {
  /** P-54 オンライン診療の作成へのロケーション */
  toTelemedicineCreateLocation: (params: {projectId: string}) => Location;

  /** P-56 オンライン診療請求 作成 */
  toTelemedicineChargeCreateLocation: (params: {projectId: string, telemedicineId: string}) => Location;

  /** P-57 オンライン診療請求 確認 */
  toTelemedicineChargeConfirmLocation: (params: {projectId: string, telemedicineId: string}) => Location;

  /** P-58-1, P-58-2, P-58-4 請求の詳細 */
  toTelemedicineChargeLocation: (params: { projectId: string, telemedicineId: string }) => Location;

  /** P-58-3 オンライン診療請求詳細（患者側決済前）へのロケーション */
  toTelemedicineChargePaymentLocation: (params: { projectId: string, telemedicineId: string }) => Location;

  /** オンライン診療予約へのロケーション */
  toTelemedicineReserveLocation: (params: { projectId?: string, questionnaireId?: string }) => Location;
}

export const useTelemedicineLocation = (): TelemedicineLocation => {
  const toTelemedicineChargeCreateLocation = (params): Location => {
    return {
      name: 'telemedicine-charge-create-custom',
      params,
    };
  };

  const toTelemedicineChargeConfirmLocation = (params): Location => {
    return {
      name: 'telemedicine-charge-create-confirm-custom',
      params,
    };
  };

  const toTelemedicineCreateLocation = (params): Location => {
    return {
      name: 'telemedicine-create-custom',
      params,
    };
  };

  const toTelemedicineChargeLocation = (params): Location => {
    return {
      name: 'telemedicine-charge-custom',
      params,
    };
  };

  const toTelemedicineChargePaymentLocation = (params): Location => {
    return {
      name: 'telemedicine-charge-payment-custom',
      params,
    };
  };

  const toTelemedicineReserveLocation = (params): Location => {
    return {
      name: 'telemedicine-reserve-custom',
      params,
    };
  };

  return {
    toTelemedicineChargeCreateLocation,
    toTelemedicineChargeConfirmLocation,
    toTelemedicineCreateLocation,
    toTelemedicineChargeLocation,
    toTelemedicineChargePaymentLocation,
    toTelemedicineReserveLocation,
  };
};
