import {
  Ref,
  ref,
} from '@nuxtjs/composition-api';

import { SpecialistChatInvoiceConfirmQuery } from '~/types/SpecialistChat';

export interface SpecialistChatState {
  specialistChatInvoiceData: Ref<SpecialistChatInvoiceConfirmQuery | null>;
  setSpecialistChatInvoiceData: (data: SpecialistChatInvoiceConfirmQuery | null) => void;
}

export const useSpecialistChatState = (): SpecialistChatState => {
  const specialistChatInvoiceData = ref<SpecialistChatInvoiceConfirmQuery | null>(null);

  const setSpecialistChatInvoiceData = (data: SpecialistChatInvoiceConfirmQuery | null): void => {
    specialistChatInvoiceData.value = data;
  };

  return {
    specialistChatInvoiceData: specialistChatInvoiceData as Ref<SpecialistChatInvoiceConfirmQuery | null>,
    setSpecialistChatInvoiceData,
  };
};
