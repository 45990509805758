import Vue from 'vue';

import PortalVue from 'portal-vue';
import { stripePlugin, stripeOptions } from './stripe';

Vue.use(stripePlugin, stripeOptions);

Vue.use(PortalVue);

Vue.use(require('vue-pusher'), {
  // eslint-disable-next-line  @typescript-eslint/naming-convention
  api_key: process.env.PUSHER_KEY,
  options: {
    cluster: process.env.PUSHER_CLUSTER,
    encrypted: true,
  },
});
