import {
  Ref,
  ref,
} from '@nuxtjs/composition-api';

import { HospitalIntroductionInvoiceConfirmQuery } from '~/types/HospitalIntroduction';

export interface HospitalIntroductionChargeState {
  hospitalIntroductionChargeInvoiceData: Ref<HospitalIntroductionInvoiceConfirmQuery | null>;
  setHospitalIntroductionChargeInvoiceData: (data: HospitalIntroductionInvoiceConfirmQuery | null) => void;
}

export const useHospitalIntroductionChargeState = (): HospitalIntroductionChargeState => {
  const hospitalIntroductionChargeInvoiceData = ref<HospitalIntroductionInvoiceConfirmQuery | null>(null);

  const setHospitalIntroductionChargeInvoiceData = (data: HospitalIntroductionInvoiceConfirmQuery| null): void => {
    hospitalIntroductionChargeInvoiceData.value = data;
  };

  return {
    hospitalIntroductionChargeInvoiceData: hospitalIntroductionChargeInvoiceData as Ref<HospitalIntroductionInvoiceConfirmQuery | null>,
    setHospitalIntroductionChargeInvoiceData,
  };
};
