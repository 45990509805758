import { Location } from 'vue-router';

import { ProfileLocation, useProfileLocation } from './profile';
import { WelcomeLocation, useWelcomeLocation } from './welcome';
import { MemberListLocation, useMemberListLocation } from './memberList';
import { VideoChatLocation, useVideoChatLocation } from './videoChat';
import { QuestionnaireLocation, useQuestionnaireLocation } from './questionnaire';
import { HospitalConditionLocation, useHospitalConditionLocation } from './hospitalCondition';
import { HospitalConditionChargeLocation, useHospitalConditionChargeLocation } from '~/plugins/locations/hospitalConditionCharged';
import { TelemedicineLocation, useTelemedicineLocation } from './telemedicine';
import { MedicalConsultingLocation, useMedicalConsultingLocation } from './medicalConsulting';
import { PaymentLocation, usePaymentLocation } from './payment';
import { SpecialistChatLocation, useSpecialistChatLocation } from './specialistChat';
import { CardType } from '~/components/settings/Timeline';
import { InvoiceCardLocation, useInvoiceCardLocation } from './invoiceCard';

export type Locations = {
  /** トップへのロケーション */
  toHomeLocation: (params?: { type: CardType, projectId: string, moduleId: string }) => Location;

  /** F04 [only doctor] 患者ごとのタイムラインへのロケーション */
  toUserTimelineLocation: (params: { projectId: string }) => Location;

  /** F07 テキストチャットへのロケーション */
  toChatLocation: (params: { projectId: string, chatId: string}) => Location;

  toChatSelectLocatin: (params: { projectId: string}) => Location

  /** F10 やることリスト */
  toTodoLocation: () => Location;

  /** [only doctor] 医療相談サマリー */
  toConsultationSummaryLocation: (params?: {patientId: string, patientName: string}) => Location;

  /** [only doctor] イベントカウント集計結果(サマリー) */
  toEventCountingSummaryLocation: (params?: {patientId: string, patientName: string}) => Location;
}
& WelcomeLocation
& MemberListLocation
& ProfileLocation
& VideoChatLocation
& HospitalConditionLocation
& HospitalConditionChargeLocation
& QuestionnaireLocation
& TelemedicineLocation
& MedicalConsultingLocation
& PaymentLocation
& SpecialistChatLocation
& InvoiceCardLocation;

/* eslint-disable class-methods-use-this */
export const useLocation = (): Locations => {
  const toHomeLocation = (params?: { type: CardType, projectId: string, moduleId: string}): Location => {
    return {
      name: 'home-custom',
      params,
    };
  };

  const toUserTimelineLocation = (params: { projectId: string }): Location => {
    return {
      name: 'home-timeline-custom',
      params,
    };
  };

  const toChatLocation = (params: { projectId: string, chatId: string}): Location => {
    return {
      name: 'chat-custom',
      params,
    };
  };

  const toChatSelectLocatin = (params: { projectId: string }): Location => {
    return {
      name: 'chat-select',
      params,
    };
  };

  const toTodoLocation = (): Location => {
    return {
      name: 'todos-custom',
    };
  };

  const toConsultationSummaryLocation = (params?: {patientId: string, patientName: string}): Location => {
    return {
      name: 'consultation-summary-custom',
      params,
    };
  };

  const toEventCountingSummaryLocation = (params?: {patientId: string, patientName: string}): Location => {
    return {
      name: 'event-counting-summary-custom',
      params,
    };
  };

  return {
    toHomeLocation,
    toUserTimelineLocation,
    toChatLocation,
    toTodoLocation,
    toChatSelectLocatin,
    toConsultationSummaryLocation,
    toEventCountingSummaryLocation,

    ...useWelcomeLocation(),

    ...useMemberListLocation(),

    ...useVideoChatLocation(),

    ...useProfileLocation(),

    ...useHospitalConditionLocation(),

    ...useHospitalConditionChargeLocation(),

    ...useQuestionnaireLocation(),

    ...useTelemedicineLocation(),

    ...useMedicalConsultingLocation(),

    ...usePaymentLocation(),

    ...useSpecialistChatLocation(),

    ...useInvoiceCardLocation(),
  };
};
