import { UserRole } from '../components/settings/User';

export default function ({ $axios, $auth0 }) {
  $axios.onRequest(async (config) => {
    // リクエスト前にアクセストークンを更新する
    try {
      await $auth0.updateTokenWithCache();
      config.headers.common.Authorization = `Bearer ${$auth0.token}`;
      return config;
    } catch (error) {
      // トークン取得失敗時はリロード
      if (process.env.DEVELOPMENT_TYPE !== 'static') {
        window.location.reload();
      }
      return config;
    }
  });

  // onError
  $axios.onError(({ response }) => {
    if (response?.data) {
      if (response.data.statusCode === 401) {
        let redirectUri = window.location.origin;

        if (localStorage.getItem('role') !== UserRole.Patient) {
          redirectUri += `?role=${UserRole.Doctor}`;
        }

        $auth0.logout({
          returnTo: redirectUri,
        });
      }

      console.error(response.data);
    }
  });
}
