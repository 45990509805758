import { render, staticRenderFns } from "./Navigation.doctor.vue?vue&type=template&id=5dbecf9c&"
import script from "./Navigation.doctor.vue?vue&type=script&lang=ts&"
export * from "./Navigation.doctor.vue?vue&type=script&lang=ts&"
import style0 from "./Navigation.doctor.vue?vue&type=style&index=0&lang=postcss&module=true&"
import style1 from "./Navigation.doctor.vue?vue&type=style&index=1&lang=postcss&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VBadge,VList,VListItem,VListItemAction,VListItemContent,VListItemTitle})
