import {
  Ref,
  ref,
} from '@nuxtjs/composition-api';
import { SearchBar } from '~/types/SearchBar';

export enum EVENT {
  SEARCH = 'search',
  CSV_DOWNLOAD = 'csvDownload',
  CSV_UPLOAD = 'csvUpload',
  CLEAR = 'clear'
}

export type EventName = '' | EVENT

export interface SearchBarState {
  searchFieldList: Ref<SearchBar[]>;
  searchItem: Ref<any>;
  eventTrigger: Ref<boolean>;
  eventName: Ref<EventName>;
  isUseFeatureCsvUpload: Ref<boolean>;
}

export const useSearchBarState = (): SearchBarState => {
  const searchFieldList = ref<SearchBar[]>([]);
  const searchItem = ref({});
  const eventTrigger = ref<boolean>(false);
  const eventName = ref<EventName>('');
  const isUseFeatureCsvUpload = ref<boolean>(false);

  return {
    searchFieldList,
    searchItem,
    eventTrigger,
    eventName,
    isUseFeatureCsvUpload,
  };
};
