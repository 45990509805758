import { EventCountingSummary } from '~/types/EventCounting';

export interface EventCountingApi {
  /** イベントカウント集計結果取得 */
  getEventCountingSummaries:() => Promise<EventCountingSummary[]>,
  /** 問診のカウント */
  postQuestionnaireCount: (
    inquiryId: string,
  ) => Promise<void>;
}

export const useEventCountingApi = (fetch): EventCountingApi => {
  const getEventCountingSummaries = async (): Promise<EventCountingSummary[]> => {
    const { data } = await fetch('/eventCounting/get');
    return data;
  };

  const postQuestionnaireCount = async (inquiryId: string): Promise<void> => {
    await fetch(`/eventCounting/inquiry/${inquiryId}`, {});
  };

  return {
    getEventCountingSummaries,
    postQuestionnaireCount,
  };
};
