import { AxiosResponse, AxiosInstance } from 'axios';
import { NuxtAxiosInstance } from '@nuxtjs/axios';

import { ProjectApi, useProjectApi } from './project';
import { ChatApi, useChatApi } from './chat';
import { SpecialistChatApi, useSpecialistChatApi } from './specialistChat';
import { ProfileApi, useProfileApi } from './profile';
import { QuestionnaireApi, useQuestionnaireApi } from './questionnaire';
import { HospitalConditionApi, useHospitalConditionApi } from './hospitalCondition';
import { TodoApi, useTodoApi } from './todo';
import { MedicalTeamApi, useMedicalTeamApi } from './medicalTeam';
import { TelemedicineApi, useTelemedicineApi } from './telemedicine';
import { ChatConsultationApi, useChatConsultationApi } from './chatConsultation';
import { MedicalConsultingApi, useMedicalConsultingApi } from './medicalConsulting';
import { EventCountingApi, useEventCountingApi } from './eventCounting';
import { InvoiceCardApi, useInvoiceCardApi } from './invoiceCard';
import { CompanyUserApi, useCompanyUserApi } from './companyUser';

export type Api = {
  fetch: <T>(url: string, body?: T) => Promise<AxiosResponse>;
}
& ProjectApi
& ChatApi
& SpecialistChatApi
& ProfileApi
& QuestionnaireApi
& HospitalConditionApi
& TodoApi
& MedicalTeamApi
& TelemedicineApi
& ChatConsultationApi
& MedicalConsultingApi
& EventCountingApi
& InvoiceCardApi
&CompanyUserApi;

const useFetch = (axios: AxiosInstance | NuxtAxiosInstance) => {
  const fetch = async <T>(url: string, body?: T): Promise<AxiosResponse> => {
    let method = 'post';
    let endpoint = url;

    if (!body || process.env.DEVELOPMENT_TYPE === 'static') {
      method = 'get';
    }

    // ローカルでサンプルjsonを見に行くとき
    if (process.env.DEVELOPMENT_TYPE === 'static') {
      endpoint = `${url.split('?')[0]}/index.json`;
    }
    axios.interceptors.response.use(
      (response: AxiosResponse) => {
        const decodeUnicode = (str: string) => {
          return str.replace(/\\u[\dA-F]{4}/gi, (match) => {
            return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
          });
        };

        const decodeResponse = (obj: any): void => {
          Object.keys(obj).forEach((key) => {
            if (typeof obj[key] === 'string') {
              obj[key] = decodeUnicode(obj[key]);
            } else if (typeof obj[key] === 'object' && obj[key] !== null) {
              decodeResponse(obj[key]);
            }
          });
        };

        if (typeof response.data === 'object' && response.data !== null) {
          decodeResponse(response.data);
        }

        return response;
      },
    );
    const postData = method === 'post' ? body : null;

    if (body instanceof FormData) {
      const headers = {
        Accept: 'multipart/form-data',
        'Content-Type': 'multipart/form-data',
      };
      const response = await axios[method](endpoint, postData, { headers });

      return response;
    }

    const response = await axios[method](endpoint, postData);

    return response;
  };

  return {
    fetch,
  };
};

export const useApi = (axios: AxiosInstance | NuxtAxiosInstance): Api => {
  const { fetch } = useFetch(axios);

  return {
    fetch,

    // プロジェクト関連 api
    ...useProjectApi(fetch, axios),

    // 医療チーム関連 api
    ...useMedicalTeamApi(fetch),

    // やることリスト関連 api
    ...useTodoApi(fetch),

    // プロフィール関連 api
    ...useProfileApi(fetch),

    // チャット関連 api
    ...useChatApi(fetch, axios),

    // 専門医チャット関連 api
    ...useSpecialistChatApi(fetch, axios),

    // 問診関連 api
    ...useQuestionnaireApi(fetch, axios),

    // 病院紹介関連 api
    ...useHospitalConditionApi(fetch, axios),

    // オンライン診療 api
    ...useTelemedicineApi(fetch, axios),

    // 医療相談 api
    ...useChatConsultationApi(fetch, axios),

    // オンライン医療相談（有料） api
    ...useMedicalConsultingApi(fetch, axios),

    // イベントカウント api
    ...useEventCountingApi(fetch),

    // 決済カード api
    ...useInvoiceCardApi(fetch),

    // 企業ユーザー api
    ...useCompanyUserApi(fetch),
  };
};
