import { QuestionTemplateListItem, QuestionTemplate } from '~/types/QuestionTemplate';
import { stringifyQuery } from '~/components/tool/Query';
import { QuestionnaireResponse, QuestionnaireSelectRequestQuery, QuestionnaireRequestQuery } from '~/types/Questionnaire';

export interface QuestionnaireApi {
  /** 基本問診取得 */
  getEnquetesByProjectId: (projectId: number | string, query) => Promise<any>;

  /** 問診テンプレート一覧の取得 */
  getQuestionnaireTemplates: () => Promise<QuestionTemplateListItem[]>;

  /** 問診の取得 */
  getQuestionnaireBaseTemplate: (templateId: string) => Promise<QuestionTemplate>

  /** 問診回答の送信 */
  postQuestionnaire: (projectId: string, questionnaireId: string, requestBody: QuestionnaireRequestQuery) => Promise<void>;

  /** 問診回答一時保存の送信 */
  postQuestionnaireTempSave: (projectId: string, questionnaireId: string, requestBody: QuestionnaireRequestQuery) => Promise<void>;

  /** F06 問診テンプレート、および回答結果の取得 */
  getQuestionnaireTemplate: (projectId: string, questionnaireId: string) => Promise<QuestionnaireResponse>;

  /** 問診テンプレートの送信 */
  postQuestionnaireTemplate: (projectId: string, requestBody: QuestionnaireSelectRequestQuery) => Promise<void>;

  /** 問診 削除 */
  removeQuestionnaire: (projectId: string, questionnaireId: string) => Promise<void>;
}

export const useQuestionnaireApi = (fetch, axios): QuestionnaireApi => {
  const getEnquetesByProjectId = async (projectId: number | string, query): Promise<any> => {
    const response = await fetch(`/projects/${projectId}/enquetes${stringifyQuery(query)}`);

    return response.data;
  };

  const getQuestionnaireTemplates = async (): Promise<QuestionTemplateListItem[]> => {
    const response = await fetch('/inquiryTemplates');
    return response.data.data;
  };

  const getQuestionnaireBaseTemplate = async (templateId: string): Promise<QuestionTemplate> => {
    const response = await fetch(`/inquiryTemplates/${templateId}`);
    return response.data.data;
  };

  const postQuestionnaire = async (projectId: string, questionnaireId: string, requestBody: QuestionnaireRequestQuery): Promise<void> => {
    const response = await fetch(`/projects/${projectId}/inquiries/${questionnaireId}`, requestBody);

    return response.data;
  };

  const postQuestionnaireTempSave = async (projectId: string, questionnaireId: string, requestBody: QuestionnaireRequestQuery): Promise<void> => {
    const response = await fetch(`/projects/${projectId}/inquiries/${questionnaireId}/tempsave`, requestBody);
    return response.data;
  };

  const getQuestionnaireTemplate = async (projectId: string, questionnaireId: string): Promise<QuestionnaireResponse> => {
    const response = await fetch(`/projects/${projectId}/inquiries/${questionnaireId}`);

    return response.data;
  };

  const postQuestionnaireTemplate = async (projectId: string, requestBody: QuestionnaireSelectRequestQuery): Promise<void> => {
    await fetch(`/projects/${projectId}/inquiries`, requestBody);
  };

  const removeQuestionnaire = async (projectId: string, questionnaireId: string): Promise<void> => {
    await axios.delete(`/projects/${projectId}/inquiries/${questionnaireId}`);
  };

  return {
    getEnquetesByProjectId,
    getQuestionnaireTemplates,
    getQuestionnaireBaseTemplate,
    postQuestionnaire,
    postQuestionnaireTempSave,
    getQuestionnaireTemplate,
    postQuestionnaireTemplate,
    removeQuestionnaire,
  };
};
