/* eslint-disable no-shadow */
export enum UserRole {
  /** 医師 */
  Doctor = 'doctor',

  /** 看護師 */
  Nurse = 'nurse',

  /** 管理栄養士 */
  Dietitian = 'registered_dietitian',

  /** 臨床心理士 */
  ClinicalPsychologist = 'clinical_psychologist',

  Concierge = 'concierge',

  Patient = 'patient',

  Guest = 'guest',
}

export enum UserGender {
  NotSet = '未設定',
  Male = '男性',
  Female = '女性',
  Other = 'その他',
}
/* eslint-enable */
