import { Plugin, Context } from '@nuxt/types';
import { Location } from 'vue-router';

import { useApi } from './apis';
// import { AppLocation } from './location';
import { useLocation } from './locations';
import { globalState } from './globalState';

import { StorageName } from '~/components/settings/Timeline';
import { removeTimelineToStorage } from '~/components/tool/Timeline';

const plugin: Plugin = async ({ app }, inject): Promise<void> => {
  removeTimelineToStorage(StorageName.Timeline);
  removeTimelineToStorage(StorageName.UserTimeline);

  inject('api', useApi(app.$axios));
  // inject('location', new AppLocation());
  inject('location', useLocation());

  const $s = globalState(app as Context);
  inject('s', $s);

  if (app.router) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    app.router.beforeEach((to, from, next): void => {
      $s.setToPage(to as Location);
      $s.setFromPage(from as Location);

      next();
    });
  }
};

export default plugin;
