import { TodoList } from '~/types/Todo';

export interface TodoApi {
  getTodoList: () => Promise<TodoList>;
}

export const useTodoApi = (fetch): TodoApi => {
  /** やることリスト一覧取得 */
  const getTodoList = async (): Promise<TodoList> => {
    const response = await fetch('/me/todoLists');
    return response.data;
  };

  return {
    getTodoList,
  };
};
