/* eslint-disable no-shadow */
export enum EditType {
  Base = 'base',
  Introduction = 'introduction',
  Disease = 'disease',
  Address = 'address',
  Tel = 'tel',
  Mailaddress = 'mailaddress',
  Password = 'password',
  Work = 'work',
  Insurance = 'insurance',
  Identification = 'identification',
  Payment = 'paymentInfo',
  LoginHistory = 'loginHistory',
}

export enum EditTypeLabel {
  Base = '基本情報',
  Introduction = '自己紹介',
  Disease = '治療中の疾患',
  Address = '住所',
  Tel = '電話番号',
  Mailaddress = 'メールアドレス',
  Password = 'パスワード',
  Work = '職務情報',
  Insurance = '保険証',
  Identification = '身分証明書',
  Payment = 'お支払い情報',
  LoginHistory = 'ログイン履歴',
}
