// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_3EVVd{height:0;position:relative}.image_2B6BH,.placeholder_2amcu{position:absolute;left:0;top:0;width:100%;height:100%;-o-object-fit:cover;object-fit:cover}@media (-ms-high-contrast:none){.image_2B6BH,.placeholder_2amcu{font-family:\"object-fit: cover\"}}.placeholder_2amcu{background-color:var(--color-base)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_3EVVd",
	"image": "image_2B6BH",
	"placeholder": "placeholder_2amcu"
};
module.exports = ___CSS_LOADER_EXPORT___;
