export interface CompanyUserApi {
  postUserCsvUpload: (requestBody: File) => Promise<string|null>;

}

export const useCompanyUserApi = (fetch): CompanyUserApi => {
  /** 企業ユーザー一括登録用CSVのアップロード */
  const postUserCsvUpload = async (requestBody: File): Promise<string|null> => {
    const formData = new FormData();
    formData.append('file', requestBody);
    try {
      const response = await fetch('/company-user/upload', formData);
      return response.data;
    } catch ({ response }) {
      return null;
    }
  };

  return {
    postUserCsvUpload,

  };
};
