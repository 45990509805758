import { Location } from 'vue-router';

export interface QuestionnaireLocation {
  /** F06 [only patient] 問診-入力へのロケーション */
  toQuestionnaireLocation: (params: {projectId: string, questionnaireId: string}) => Location;

  /** F06 [only patient] 問診-入力へのロケーション-確認へのロケーション */
  toQuestionnaireFormConfirmLocation: (params: { projectId: string, questionnaireId: string }) => Location;

  /** F06 問診-回答結果へのロケーション */
  toQuestionnaireResultLocation: (params: { projectId: string, questionnaireId: string }) => Location;

  /** F06 [only doctor] 問診-確認へのロケーション */
  toQuestionnaireDetailLocation: (params: { projectId: string, questionnaireId: string }) => Location;

  /** F06 [only doctor] 問診-選択リストへのロケーション */
  toQuestionnaireSelectLocation: (params: { projectId: string }) => Location;

  /** F06 [only doctor] 問診-選択リスト確認へのロケーション */
  toQuestionnaireSelectConfirmLocation: (params: { projectId: string, templateId: string }) => Location;
}

export const useQuestionnaireLocation = (): QuestionnaireLocation => {
  const toQuestionnaireLocation = (params): Location => {
    return {
      name: 'questionnaire-custom',
      params,
    };
  };

  const toQuestionnaireFormConfirmLocation = (params): Location => {
    return {
      name: 'questionnaire-confirm-custom',
      params,
    };
  };

  const toQuestionnaireResultLocation = (params): Location => {
    return {
      name: 'questionnaire-result-custom',
      params,
    };
  };

  const toQuestionnaireDetailLocation = (params): Location => {
    return {
      name: 'questionnaire-detail-custom',
      params,
    };
  };

  const toQuestionnaireSelectLocation = (params): Location => {
    return {
      name: 'questionnaire-select-custom',
      params,
    };
  };

  const toQuestionnaireSelectConfirmLocation = (params): Location => {
    return {
      name: 'questionnaire-select-confirm-custom',
      params,
    };
  };

  return {
    toQuestionnaireLocation,
    toQuestionnaireFormConfirmLocation,
    toQuestionnaireResultLocation,
    toQuestionnaireDetailLocation,
    toQuestionnaireSelectLocation,
    toQuestionnaireSelectConfirmLocation,
  };
};
