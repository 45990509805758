import {
  Ref,
  ref,
} from '@nuxtjs/composition-api';

import { TelemedicineInvoiceConfirmQuery } from '~/types/Telemedicine';

export interface TelemedicineState {
  telemedicineInvoiceData: Ref<TelemedicineInvoiceConfirmQuery | null>;
  setTelemedicineInvoiceData: (data: TelemedicineInvoiceConfirmQuery | null) => void;
}

export const useTelemedicineState = (): TelemedicineState => {
  const telemedicineInvoiceData = ref<TelemedicineInvoiceConfirmQuery | null>(null);

  const setTelemedicineInvoiceData = (data: TelemedicineInvoiceConfirmQuery | null): void => {
    telemedicineInvoiceData.value = data;
  };

  return {
    telemedicineInvoiceData: telemedicineInvoiceData as Ref<TelemedicineInvoiceConfirmQuery | null>,
    setTelemedicineInvoiceData,
  };
};
