import { Context } from '@nuxt/types';
import {
  Ref,
  ref,
} from '@nuxtjs/composition-api';
import { CardInfo } from '~/types/Payment';

export interface ProfileState {
  cardInfo: Ref<CardInfo | undefined>;
  getCardInfoForPatient: () => Promise<CardInfo | undefined>;
  updateCardInfoForPatient: () => Promise<void>;
}

export const useProfileState = (context: Context): ProfileState => {
  const cardInfo = ref<CardInfo | undefined>();

  /**
   * 患者アカウントの場合に登録したクレジットカードの情報を取得する
   */
  const getCardInfoForPatient = async (): Promise<CardInfo | undefined> => {
    if (context.$auth0.user.registeredPayments && context.$auth0.user.registeredPayments.length > 0) {
      if (!cardInfo.value) {
        const getCardInfo = await context.$api.getCardInfo();
        cardInfo.value = getCardInfo;
        return getCardInfo;
      }

      return cardInfo.value;
    }

    return undefined;
  };

  /**
   * 患者アカウントの場合に登録したクレジットカードの情報を更新する
   */
  const updateCardInfoForPatient = async (): Promise<void> => {
    if (context.$auth0.user.registeredPayments && context.$auth0.user.registeredPayments.length > 0) {
      const getCardInfo = await context.$api.getCardInfo();
      cardInfo.value = getCardInfo;
    }
  };

  return {
    cardInfo,
    getCardInfoForPatient,
    updateCardInfoForPatient,
  };
};
