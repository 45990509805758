// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media only screen and (min-width:600px){.variableGutter_3EbuW{padding-left:var(--width-navigation-desctop)}}@media only screen and (min-width:1240px){.variableGutter_3EbuW{padding-left:var(--width-navigation-infinity)}}.content_3A7wo{padding-top:var(--gutter-container-top);padding-bottom:var(--gutter-container-bottom)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"variableGutter": "variableGutter_3EbuW",
	"content": "content_3A7wo"
};
module.exports = ___CSS_LOADER_EXPORT___;
