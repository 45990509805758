import { Invoice } from '~/types/Invoice';
import { CreateInvoiceCardRequestQueryFormData } from '~/types/Timeline';

export interface InvoiceCardApi {
  /** 決済カード作成 */
  createInvoice: (projectId: string, requestBody: CreateInvoiceCardRequestQueryFormData) => Promise<void>

  /** 診療明細情報取得 */
  getInvoice: (projectId: string, invoiceId: string) => Promise<Invoice | null>

  /** 支払いの送信 */
  postInvoicePayment: (projectId: string, invoiceId: string) => Promise<void>
}

export const useInvoiceCardApi = (fetch): InvoiceCardApi => {
  const createInvoice = async (projectId: string, requestBody): Promise<void> => {
    await fetch(`/projects/${projectId}/invoice`, requestBody);
  };

  const getInvoice = async (projectId: string, invoiceId: string): Promise<Invoice | null> => {
    try {
      const { data } = await fetch(`/projects/${projectId}/invoice/${invoiceId}/payments`);
      return data;
    } catch ({ response }) {
      if ((response as any)?.status !== 401 || (response as any)?.data.statusCode !== 401) {
        throw new Error('診療明細情報が取得できませんでした');
      }
      return null;
    }
  };

  const postInvoicePayment = async (projectId: string, invoiceId: string): Promise<void> => {
    const response = await fetch(`/projects/${projectId}/invoice/${invoiceId}/paymentSubmit`, {});
    if (response.data.status === 'failed') throw new Error('カード情報の取得に失敗しました。');
  };

  return {
    createInvoice,
    getInvoice,
    postInvoicePayment,
  };
};
