import {
  Ref,
  ref,
} from '@nuxtjs/composition-api';

import { MedicalConsultingInvoiceConfirmQuery } from '~/types/MedicalConsulting';

export interface MedicalConsultingState {
  medicalConsultingInvoiceData: Ref<MedicalConsultingInvoiceConfirmQuery | null>;
  setMedicalConsultingInvoiceData: (data: MedicalConsultingInvoiceConfirmQuery | null) => void;
}

export const useMedicalConsultingState = (): MedicalConsultingState => {
  const medicalConsultingInvoiceData = ref<MedicalConsultingInvoiceConfirmQuery | null>(null);

  const setMedicalConsultingInvoiceData = (data: MedicalConsultingInvoiceConfirmQuery | null): void => {
    medicalConsultingInvoiceData.value = data;
  };

  return {
    medicalConsultingInvoiceData: medicalConsultingInvoiceData as Ref<MedicalConsultingInvoiceConfirmQuery | null>,
    setMedicalConsultingInvoiceData,
  };
};
