/* eslint-disable no-shadow, @typescript-eslint/no-namespace */
export namespace MimeType {
  export enum Videos {
    Mp4 = 'video/mp4',
    Mov = 'video/quicktime',
  }

  export enum Images {
    Png = 'image/png',
    Jpg = 'image/jpeg',
    Svg = 'image/svg+xml',
    Gif = 'image/gif',
    Bmp = 'image/bmp',
  }

  export enum Files {
    Text = 'text/plain',
    Xls = 'application/vnd.ms-excel',
    Xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    Doc = 'application/msword',
    Docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    Ppt = 'application/vnd.ms-powerpoint',
    Pptx = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    Pdf = 'application/pdf',
  }
}

// eslint-disable-next-line
export const IgnoreExtensions = ['bat', 'cmd', 'com', 'cpl', 'exe', 'EXE', 'scr', 'js', 'vbs', 'wsf', 'hta', 'msi'];

// eslint-disable-next-line
export const WhiteList = [
  ...Object.values(MimeType.Videos),
  ...Object.values(MimeType.Images),
  MimeType.Files.Text,
  MimeType.Files.Xlsx,
  MimeType.Files.Docx,
  MimeType.Files.Pptx,
  MimeType.Files.Pdf,
  '.heic',
  '.csv',
  '.key',
  '.numbers',
  '.pages',
];
/* eslint-enable */
