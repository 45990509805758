import { Location } from 'vue-router';

export interface WelcomeLocation {
  /** F01 初回ログインへのロケーション */
  toWelcomeLocation: () => Location;

  /** F01 初回アンケートへのロケーション */
  toWelcomeInterviewLocation: () => Location;
}

export const useWelcomeLocation = (): WelcomeLocation => {
  const toWelcomeLocation = (): Location => {
    return {
      name: 'welcome-custom',
    };
  };

  const toWelcomeInterviewLocation = (): Location => {
    return {
      name: 'welcome-interview-custom',
    };
  };

  return {
    toWelcomeLocation,
    toWelcomeInterviewLocation,
  };
};
