import {
  Ref,
  ref,
} from '@nuxtjs/composition-api';

import { InvoiceConfirmQuery } from '~/types/Invoice';

export interface InvoiceCardState {
  invoiceCardInvoiceData: Ref<InvoiceConfirmQuery | null>;
  setInvoiceCardInvoiceData: (data: InvoiceConfirmQuery | null) => void;
}

export const useInvoiceCardState = (): InvoiceCardState => {
  const invoiceCardInvoiceData = ref<InvoiceConfirmQuery | null>(null);

  const setInvoiceCardInvoiceData = (data: InvoiceConfirmQuery | null): void => {
    invoiceCardInvoiceData.value = data;
  };

  return {
    invoiceCardInvoiceData: invoiceCardInvoiceData as Ref<InvoiceConfirmQuery | null>,
    setInvoiceCardInvoiceData,
  };
};
