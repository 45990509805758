// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_1eWkg{background-color:var(--color-white);z-index:1;left:0}@media only screen and (max-width:599px){.container_1eWkg{position:sticky;display:flex;align-items:center;justify-content:center;bottom:0;left:0;width:100%;height:var(--height-bottom-nav);box-shadow:0 -2px 4px rgba(0,0,0,.1)}}@media only screen and (min-width:600px){.container_1eWkg{position:fixed;top:0;padding-top:var(--height-header);width:var(--width-navigation-desctop);height:100vh;box-shadow:2px 0 4px rgba(0,0,0,.1)}}@media only screen and (min-width:1240px){.container_1eWkg{display:none}}.button_ckHd9{display:flex;align-items:center;justify-content:center;flex-direction:column;position:relative;color:var(--v-accent-base)!important}.button_ckHd9[aria-current]{color:var(--v-anchor-base)!important}@media only screen and (min-width:600px){.button_ckHd9{margin:20px 0 30px}}@media only screen and (max-width:599px){.button_ckHd9{min-width:64px;max-width:168px;width:30%}}.icon_16vAm{font-size:30px}.text_3xqLG{font-size:10px;font-weight:700}.badge_1FXd4{position:absolute!important;top:6px;left:50%;-webkit-transform:translateX(-50%);transform:translateX(-50%);margin-left:10px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_1eWkg",
	"button": "button_ckHd9",
	"icon": "icon_16vAm",
	"text": "text_3xqLG",
	"badge": "badge_1FXd4"
};
module.exports = ___CSS_LOADER_EXPORT___;
