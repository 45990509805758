import { Location } from 'vue-router';

export interface HospitalConditionChargeLocation {
  /** [only patient] 病院紹介（有料）-希望条件入力へのロケーション */
  toHospitalRequirementChargeFormLocation: (params: {
    projectId: string;
    hospitalConditionId: string;
  }) => Location;

  /** [only patient] 病院紹介（有料）-希望条件入力-確認へのロケーション */
  toHospitalRequirementChargeFormConfirmLocation: (params: {
    projectId: string;
    hospitalConditionId: string;
  }) => Location;

  /** 病院紹介（有料） 希望条件 回答結果へのロケーション */
  toHospitalRequirementChargeResultLocation: (params: {
    projectId: string;
    hospitalConditionId: string;
  }) => Location;

  /** [only doctor] 病院紹介（有料） 希望条件 選択リスト */
  toHospitalRequirementChargeListLocation: (params: {
    projectId: string;
  }) => Location;

  /** [only doctor] 病院紹介（有料） 希望条件 選択リスト 確認 */
  toHospitalRequirementChargeListConfirmLocation: (params: {
    projectId: string;
    templateId: string;
  }) => Location;

  /** [only doctor] 病院紹介（有料）-希望条件-確認へのロケーション */
  toHospitalRequirementChargeDetailLocation: (params: {
    projectId: string;
    hospitalConditionId: string;
  }) => Location;

  /** [only doctor] 病院紹介（有料） 入力 */
  toHospitalIntroductionChargeFormLocation: (params: {
    projectId: string;
    hospitalConditionId: string;
  }) => Location;

  /** [only doctor] 病院紹介（有料） 確認 */
  toHospitalIntroductionChargeFormConfirmLocation: (params: {
    projectId: string;
    hospitalConditionId: string;
  }) => Location;

  /** 病院紹介（有料） 結果 */
  toHospitalIntroductionChargeResultLocation: (params: {
    projectId: string;
    hospitalIntroductionId: string;
  }) => Location ;

  /** 病院紹介（有料） 履歴 */
  toHospitalIntroductionChargeHistoryLocation: (params: {
    projectId: string;
    hospitalIntroductionId: string;
  }) => Location;

  /** 病院紹介（有料） 請求作成 */
  toHospitalIntroductionChargeCreateLocation: (params: {
    projectId: string;
    hospitalIntroductionId: string;
  }) => Location;

  /** 病院紹介（有料） 請求の詳細 */
  toHospitalIntroductionChargeLocation: (params: {
    projectId: string;
    hospitalIntroductionPaymentId: string;
  }) => Location;

  toHospitalIntroductionChargeCreateConfirmLocation: (params: {
    projectId: string;
    hospitalIntroductionId: string;
  }) => Location;

  /** 病院紹介（有料）請求詳細（患者側決済前）へのロケーション */
  toHospitalIntroductionChargePaymentLocation: (params: { projectId: string, hospitalIntroductionPaymentId: string }) => Location;
}

export const useHospitalConditionChargeLocation = (): HospitalConditionChargeLocation => {
  const toHospitalRequirementChargeFormLocation = (params): Location => {
    return {
      name: 'hospital-requirement-charge-form-custom',
      params,
    };
  };

  const toHospitalRequirementChargeFormConfirmLocation = (params): Location => {
    return {
      name: 'hospital-requirement-charge-form-confirm-custom',
      params,
    };
  };

  const toHospitalRequirementChargeResultLocation = (params): Location => {
    return {
      name: 'hospital-requirement-charge-result-custom',
      params,
    };
  };

  const toHospitalRequirementChargeListLocation = (params): Location => {
    return {
      name: 'hospital-requirement-charge-list-custom',
      params,
    };
  };

  const toHospitalRequirementChargeListConfirmLocation = (params): Location => {
    return {
      name: 'hospital-requirement-charge-list-confirm-custom',
      params,
    };
  };

  const toHospitalRequirementChargeDetailLocation = (params): Location => {
    return {
      name: 'hospital-requirement-charge-detail-custom',
      params,
    };
  };

  const toHospitalIntroductionChargeLocation = (params): Location => {
    return {
      name: 'hospital-introduction-charge-custom',
      params,
    };
  };

  const toHospitalIntroductionChargeFormLocation = (params): Location => {
    return {
      name: 'hospital-introduction-charge-form-custom',
      params,
    };
  };

  const toHospitalIntroductionChargeFormConfirmLocation = (
    params,
  ): Location => {
    return {
      name: 'hospital-introduction-charge-form-confirm-custom',
      params,
    };
  };

  const toHospitalIntroductionChargeResultLocation = (params): Location => {
    return {
      name: 'hospital-introduction-charge-result-custom',
      params,
    };
  };

  const toHospitalIntroductionChargeHistoryLocation = (params): Location => {
    return {
      name: 'hospital-introduction-charge-history-custom',
      params,
    };
  };

  const toHospitalIntroductionChargeCreateLocation = (params): Location => {
    return {
      name: 'hospital-introduction-charge-create-custom',
      params,
    };
  };

  const toHospitalIntroductionChargeCreateConfirmLocation = (params): Location => {
    return {
      name: 'hospital-introduction-charge-create-confirm-custom',
      params,
    };
  };

  const toHospitalIntroductionChargePaymentLocation = (params): Location => {
    return {
      name: 'hospital-introduction-charge-payment-custom',
      params,
    };
  };

  return {
    toHospitalRequirementChargeFormLocation,
    toHospitalRequirementChargeFormConfirmLocation,
    toHospitalRequirementChargeResultLocation,
    toHospitalRequirementChargeListLocation,
    toHospitalRequirementChargeListConfirmLocation,
    toHospitalRequirementChargeDetailLocation,
    toHospitalIntroductionChargeLocation,
    toHospitalIntroductionChargeFormLocation,
    toHospitalIntroductionChargeFormConfirmLocation,
    toHospitalIntroductionChargeResultLocation,
    toHospitalIntroductionChargeHistoryLocation,
    toHospitalIntroductionChargeCreateLocation,
    toHospitalIntroductionChargeCreateConfirmLocation,
    toHospitalIntroductionChargePaymentLocation,
  };
};
