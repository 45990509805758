// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_1gxvQ{background-color:var(--color-white);z-index:1;left:0}@media only screen and (max-width:599px){.container_1gxvQ{position:sticky;display:flex;align-items:center;justify-content:center;bottom:0;left:0;width:100%;height:var(--height-bottom-nav);box-shadow:0 -2px 4px rgba(0,0,0,.1)}}@media only screen and (min-width:600px){.container_1gxvQ{position:fixed;top:0;padding-top:var(--height-header);width:var(--width-navigation-desctop);height:100vh;box-shadow:2px 0 4px rgba(0,0,0,.1)}}@media only screen and (min-width:1240px){.container_1gxvQ{display:none}}.button_2KXAp{display:flex;align-items:center;justify-content:center;flex-direction:column;position:relative;color:var(--v-accent-base)!important}.button_2KXAp[aria-current]{color:var(--v-anchor-base)!important}@media only screen and (min-width:600px){.button_2KXAp{margin:20px 0 30px;width:100%}}@media only screen and (max-width:599px){.button_2KXAp{min-width:64px;max-width:168px;width:30%}}.routerLink_DwY48{display:flex;align-items:center;justify-content:center;flex-direction:column;position:relative;color:var(--v-accent-base)!important}.routerLink_DwY48[aria-current]{color:var(--v-anchor-base)!important}@media only screen and (min-width:600px){.routerLink_DwY48{margin:20px 0 30px}}@media only screen and (max-width:599px){.routerLink_DwY48{min-width:64px;max-width:168px;width:30%}}.icon_2az7b{font-size:30px}.text_1wBjT{font-size:10px;font-weight:700}.badge_cHhRH{position:absolute!important;top:6px;left:50%;-webkit-transform:translateX(-50%);transform:translateX(-50%);margin-left:10px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_1gxvQ",
	"button": "button_2KXAp",
	"routerLink": "routerLink_DwY48",
	"icon": "icon_2az7b",
	"text": "text_1wBjT",
	"badge": "badge_cHhRH"
};
module.exports = ___CSS_LOADER_EXPORT___;
