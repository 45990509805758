// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".image_Q3UhJ{flex-shrink:0;border-radius:20%;overflow:hidden;border:1px solid var(--color-border-thin);position:relative;z-index:1}.image_Q3UhJ.isCircle_1Po2V{border-radius:100%}.image_Q3UhJ.isBadge_2v0Dk{border-radius:100%;box-shadow:0 2px 8px rgba(var(--color-text-default),.1);border:8px solid var(--color-base)}@media only screen and (max-width:599px){.image_Q3UhJ.isBadge_2v0Dk{border-width:4px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": "image_Q3UhJ",
	"isCircle": "isCircle_1Po2V",
	"isBadge": "isBadge_2v0Dk"
};
module.exports = ___CSS_LOADER_EXPORT___;
