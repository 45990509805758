import {
  Ref,
  ref,
} from '@nuxtjs/composition-api';
import VueRouter, { Route, Location } from 'vue-router';

export interface RootState {
  isTablet: Ref<boolean>;
  isDesctop: Ref<boolean>;
  to: Ref<Location | null>;
  from: Ref<Location | null>;
  initRootState: () => void,
  setToPage: (toArg: Location) => void,
  setFromPage: (toArg: Location) => void,
  resize: () =>void,
  referrerBack: ($router: VueRouter) => Promise<Route> | void,
}

export const useRootState = (): RootState => {
  const isTablet = ref<boolean>(false);
  const isDesctop = ref<boolean>(false);
  const to = ref<Location | null>(null);
  const from = ref<Location | null>(null);

  const setToPage = (toArg: Location): void => {
    to.value = toArg;
  };

  const setFromPage = (fromArg: Location): void => {
    from.value = fromArg;
  };

  const resize = (): void => {
    isTablet.value = window.matchMedia('(min-width: 600px)').matches;
    isDesctop.value = window.matchMedia('(min-width: 1240px)').matches;
  };

  const referrerBack = ($router: VueRouter): Promise<Route> | void => {
    if (!from.value?.name) {
      return $router.push('/');
    }

    return $router.back();
  };

  const initRootState = (): void => {
    window.addEventListener('resize', resize, { capture: false, passive: true });
    resize();
  };

  return {
    isTablet,
    isDesctop,
    to,
    from,
    initRootState,
    setToPage,
    setFromPage,
    resize,
    referrerBack,
  };
};
