import {
  computed,
  useContext,
} from '@nuxtjs/composition-api';
import { Location } from 'vue-router';

export const useNavigation = () => {
  const { $s, $location } = useContext();

  const visibleBadge = computed(() => {
    if (!$s.todoList.value) {
      return false;
    }

    const { deadline, noDeadline } = $s.todoList.value;
    return deadline.length > 0 || noDeadline.length > 0;
  });

  const toChatLocation = (projectId: string, chatId: string): Location | null => {
    if (projectId) {
      return $location.toChatLocation({ projectId, chatId });
    }

    return null;
  };

  return {
    visibleBadge,
    toChatLocation,
  };
};

export { default as ComponentNavigationPatient } from './Navigation.patient.vue';
export { default as ComponentNavigationDoctor } from './Navigation.doctor.vue';
